import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import "./Analytics.css";

// Chart Components -------------------
import RectChart from "../../charts/RectChart";
import AreaChart from "../../charts/AreaChart";
import StackRectChart from "../../charts/StackRectChart";
import DonutChart from "../../charts/DonutChart.js";

// Data -------------------------------
import energy_total_continents from "../../data/analytics/dashboard/energy_total_continents.js";
import energy_total_sources from "../../data/analytics/dashboard/energy_total_sources.js";
import energy_per_source from "../../data/analytics/dashboard/energy_per_source.js";
import energy_per_continent from "../../data/analytics/dashboard/source_per_country.js";

// Object Data -------------------------
import coursesdata from "../courses/CoursesData.js";


const continent = ["America", "Europe", "Africa", "Asia", "Oceania"];
const years = ["2021", "2022", "2023"];

function Dashboard() {
  const parm = useLocation();
  let index = parm.state == null ? 0 : parm.state;
  let object = coursesdata[index];

  const [activeContinent, setActiveContinent] = useState("Africa");
  const [continentListValue, setContinentListValue] = useState("Africa");
  const [yearListValue, setYearListValue] = useState("2021");

  const energy_per_source_data = useMemo(() =>
    energy_per_source
      .filter((i) => i.Continent === continentListValue)
      .filter((i) => i.date === yearListValue + "-01-01")
  );
  const energy_per_continent_data = useMemo(() =>
    energy_per_continent
      .filter((i) => i.Continent === continentListValue)
      .filter((i) => i.date === yearListValue + "-01-01")
  );
  const forecast_per_source_data = useMemo(() =>
    energy_total_sources
      .filter((i) => i.Continent === continentListValue)
      .filter((i) => i.date === yearListValue + "-01-01")
  );

  const handleClick = (continent) => {
    setActiveContinent(continent);
  };

  return (
    <Container fluid className="">
      <Row className="sport-analytics-grid">
        <div className="sport-analytics-title">
          <div className="container title-prompt">
            <div className="row">
              <div className="col-lg-6 px-0 title-prompt">
                <h1 className="display-4 fst-italic">{object.title}</h1>
                <p className="lead my-3">{object.content}</p>
              </div>
              <div className="col-lg-6 px-0 title-prompt">
                <img
                  width="80%"
                  height="80%"
                  alt=""
                  className="image-title-prompt"
                  src={object.image_title_prompt}
                />
              </div>
            </div>
          </div>
          <h3 className="dashboard-title-style">Continents</h3>
        </div>
        <div className="energy_total_per_year border-areas">
          <AreaChart data={energy_total_continents} />
        </div>
        <div className="sport-analytics-sub-title">
          <Row className="sport-analytics-sub-title-grid">
            <Col className="dashboard-sub-title-button">
              {continent.map((item, i) => (
                <button
                  type="button"
                  className={`dropdown-continent ${
                    activeContinent === item ? "active" : ""
                  }`}
                  onClick={() => {
                    setContinentListValue(item);
                    handleClick(item);
                  }}
                >
                  {item}
                </button>
              ))}
            </Col>
            <Col className="dashboard-sub-title-check">
              <Form>
                <Form.Group as={Row}>
                  {years.map((item, i) => (
                    <Col>
                      <Form.Check
                        type="switch"
                        id={`custom-switch-${i}`}
                        label={item}
                        checked={yearListValue === item}
                        onChange={() => setYearListValue(item)}
                      />
                    </Col>
                  ))}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="energy_per_country border-areas">
          <DonutChart
            data={energy_per_continent_data}
            year={yearListValue}
            title={"Energy Distribution by Country"}
          />
        </div>
        <div className="energy_per_source border-areas">
          <StackRectChart
            data={energy_per_source_data}
            year={yearListValue}
            title={"Consume Energy per Source"}
          />
        </div>
        <div className="energy_per_city border-areas">
          <RectChart
            data={forecast_per_source_data}
            year={yearListValue}
            title={"Forecast Consume per Source"}
          />
        </div>
      </Row>
    </Container>
  );
}

export default Dashboard;
