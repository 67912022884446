import React from "react";

function Contact() {
  return (
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
              <h2 className="text-10 fw-600 mb-5">Let's get in touch</h2>
              <p className="text-5 mb-5 justify-text ml-2 mr-2">
                I enjoy discussing new projects and design challenges. Please
                share as much info as possible so we can get the most out of our
                first catch-up.
              </p>
              <h3 className="text-5 fw-600">Located:</h3>
              <address className="text-4">Gosford, NSW, Australia.</address>
              {/* <h3 className="text-5 fw-600">Call:</h3>
              <p className="text-4">(+61) 470 635 624</p>
              <h3 className="text-5 fw-600">Email:</h3>
              <p className="text-4">moises.osorio@live.com</p> */}
              <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
                <li className="social-icons-medium">
                  <a
                    data-bs-toggle="tooltip"
                    href="https://moises-osorio.medium.com/"
                    target="_blank"
                    title=""
                    data-bs-original-title="Medium"
                    rel="noreferrer"
                  >
                    <i className="fab fa-medium"></i>
                  </a>
                </li>
                <li className="social-icons-linkedin">
                  <a
                    data-bs-toggle="tooltip"
                    href="https://www.linkedin.com/in/moises-o-a5967872/"
                    target="_blank"
                    title=""
                    data-bs-original-title="LinkedIn"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li className="social-icons-github">
                  <a
                    data-bs-toggle="tooltip"
                    href="https://github.com/moisesosorio"
                    target="_blank"
                    title=""
                    data-bs-original-title="GitHub"
                    rel="noreferrer"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
                Estimate your project?
              </h2>
              <form
                id="contact-form"
                className="form-border"
                action="mailto:mosorio3007@email.com"
                method="post"
              >
                <div className="row g-4">
                  <div className="col-12">
                    <label className="form-label" htmlFor="name">
                      What is Your Name:
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control py-1"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="email">
                      Your Email Address:
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="form-control py-1"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label className="form-label" htmlFor="form-message">
                      How can I Help you?:
                    </label>
                    <textarea
                      id="form-message"
                      name="form-message"
                      className="form-control py-1"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 text-center text-lg-start">
                    <button
                      id="submit-btn"
                      className="btn btn-dark rounded-0"
                      type="submit"
                    >
                      Send
                      <span className="ms-3">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Contact;
