const energy_total_sources = [
    {
        Continent: "Africa",
        date: "2023-01-01",
        Dimension: "Plant",
        Amount: 25,
        Forecast:10,
      },
      {
        Continent: "Africa",
        date: "2023-01-01",
        Dimension: "Water",
        Amount: 31,
        Forecast: 20,
      },
      {
        Continent: "Africa",
        date: "2023-01-01",
        Dimension: "Wind",
        Amount: 43,
        Forecast: 30,
      },
      {
        Continent: "Africa",
        date: "2023-01-01",
        Dimension: "Solar",
        Amount: 50,
        Forecast: 40,
      },
    
      {
        Continent: "Africa",
        date: "2022-01-01",
        Dimension: "Nigeria",
        Amount: 18,
      },
      {
        Continent: "Africa",
        date: "2022-01-01",
        Dimension: "South Africa",
        Amount: 22,
      },
      {
        Continent: "Africa",
        date: "2022-01-01",
        Dimension: "Egypt",
        Amount: 15,
      },
      {
        Continent: "Africa",
        date: "2022-01-01",
        Dimension: "Congo",
        Amount: 25,
      },
      {
        Continent: "Africa",
        date: "2022-01-01",
        Dimension: "Others",
        Amount: 20,
      },
    
      {
        Continent: "Africa",
        date: "2021-01-01",
        Dimension: "Nigeria",
        Amount: 13,
      },
      {
        Continent: "Africa",
        date: "2021-01-01",
        Dimension: "South Africa",
        Amount: 27,
      },
      {
        Continent: "Africa",
        date: "2021-01-01",
        Dimension: "Egypt",
        Amount: 21,
      },
      {
        Continent: "Africa",
        date: "2021-01-01",
        Dimension: "Congo",
        Amount: 29,
      },
      {
        Continent: "Africa",
        date: "2021-01-01",
        Dimension: "Others",
        Amount: 10,
      },
      {
        Continent: "America",
        date: "2023-01-01",
        Dimension: "Peru",
        Amount: 27,
      },
      {
        Continent: "America",
        date: "2023-01-01",
        Dimension: "Brazil",
        Amount: 11,
      },
      {
        Continent: "America",
        date: "2023-01-01",
        Dimension: "Argentina",
        Amount: 38,
      },
      {
        Continent: "America",
        date: "2023-01-01",
        Dimension: "Chile",
        Amount: 14,
      },
      {
        Continent: "America",
        date: "2023-01-01",
        Dimension: "Others",
        Amount: 10,
      },
    
      {
        Continent: "America",
        date: "2022-01-01",
        Dimension: "Peru",
        Amount: 25,
      },
      {
        Continent: "America",
        date: "2022-01-01",
        Dimension: "Brazil",
        Amount: 25,
      },
      {
        Continent: "America",
        date: "2022-01-01",
        Dimension: "Argentina",
        Amount: 26,
      },
      {
        Continent: "America",
        date: "2022-01-01",
        Dimension: "Chile",
        Amount: 19,
      },
      {
        Continent: "America",
        date: "2022-01-01",
        Dimension: "Others",
        Amount: 5,
      },
    
      {
        Continent: "America",
        date: "2021-01-01",
        Dimension: "Peru",
        Amount: 15,
      },
      {
        Continent: "America",
        date: "2021-01-01",
        Dimension: "Brazil",
        Amount: 31,
      },
      {
        Continent: "America",
        date: "2021-01-01",
        Dimension: "Argentina",
        Amount: 28,
      },
      {
        Continent: "America",
        date: "2021-01-01",
        Dimension: "Chile",
        Amount: 10,
      },
      {
        Continent: "America",
        date: "2021-01-01",
        Dimension: "Others",
        Amount: 5,
      },
      {
        Continent: "Oceania",
        date: "2023-01-01",
        Dimension: "Australia",
        Amount: 60,
      },
      {
        Continent: "Oceania",
        date: "2023-01-01",
        Dimension: "New Zeland",
        Amount: 20,
      },
      {
        Continent: "Oceania",
        date: "2023-01-01",
        Dimension: "Fiji",
        Amount: 10,
      },
      {
        Continent: "Oceania",
        date: "2023-01-01",
        Dimension: "New Caledonia",
        Amount: 5,
      },
      {
        Continent: "Oceania",
        date: "2023-01-01",
        Dimension: "PNG",
        Amount: 5,
      },
      {
        Continent: "Oceania",
        date: "2022-01-01",
        Dimension: "Australia",
        Amount: 50,
      },
      {
        Continent: "Oceania",
        date: "2022-01-01",
        Dimension: "New Zeland",
        Amount: 21,
      },
      {
        Continent: "Oceania",
        date: "2022-01-01",
        Dimension: "Fiji",
        Amount: 10,
      },
      {
        Continent: "Oceania",
        date: "2022-01-01",
        Dimension: "New Caledonia",
        Amount: 10,
      },
      {
        Continent: "Oceania",
        date: "2022-01-01",
        Dimension: "PNG",
        Amount: 8,
      },
    
      {
        Continent: "Oceania",
        date: "2021-01-01",
        Dimension: "Australia",
        Amount: 40,
      },
      {
        Continent: "Oceania",
        date: "2021-01-01",
        Dimension: "New Zeland",
        Amount: 19,
      },
      {
        Continent: "Oceania",
        date: "2021-01-01",
        Dimension: "Fiji",
        Amount: 15,
      },
      {
        Continent: "Oceania",
        date: "2021-01-01",
        Dimension: "New Caledonia",
        Amount: 13,
      },
      {
        Continent: "Oceania",
        date: "2021-01-01",
        Dimension: "PNG",
        Amount: 13,
      },
      {
        Continent: "Europe",
        date: "2021-01-01",
        Dimension: "Germany",
        Amount: 37,
      },
      {
        Continent: "Europe",
        date: "2021-01-01",
        Dimension: "Netherlands",
        Amount: 21,
      },
      {
        Continent: "Europe",
        date: "2021-01-01",
        Dimension: "Spain",
        Amount: 15,
      },
      {
        Continent: "Europe",
        date: "2021-01-01",
        Dimension: "Sweeden",
        Amount: 24,
      },
      {
        Continent: "Europe",
        date: "2021-01-01",
        Dimension: "Others",
        Amount: 3,
      },
    
      {
        Continent: "Europe",
        date: "2022-01-01",
        Dimension: "Germany",
        Amount: 40,
      },
      {
        Continent: "Europe",
        date: "2022-01-01",
        Dimension: "Netherlands",
        Amount: 18,
      },
      {
        Continent: "Europe",
        date: "2022-01-01",
        Dimension: "Spain",
        Amount: 16,
      },
      {
        Continent: "Europe",
        date: "2022-01-01",
        Dimension: "Sweeden",
        Amount: 18,
      },
      {
        Continent: "Europe",
        date: "2022-01-01",
        Dimension: "Others",
        Amount: 8,
      },
    
      {
        Continent: "Europe",
        date: "2023-01-01",
        Dimension: "Germany",
        Amount: 45,
      },
      {
        Continent: "Europe",
        date: "2023-01-01",
        Dimension: "Netherlands",
        Amount: 15,
      },
      {
        Continent: "Europe",
        date: "2023-01-01",
        Dimension: "Spain",
        Amount: 10,
      },
      {
        Continent: "Europe",
        date: "2023-01-01",
        Dimension: "Sweeden",
        Amount: 25,
      },
      {
        Continent: "Europe",
        date: "2023-01-01",
        Dimension: "Others",
        Amount: 5,
      },
      {
        Continent: "Asia",
        date: "2021-01-01",
        Dimension: "China",
        Amount: 21,
      },
      ,
      {
        Continent: "Asia",
        date: "2021-01-01",
        Dimension: "Nepal",
        Amount: 10,
      },
      {
        Continent: "Asia",
        date: "2021-01-01",
        Dimension: "India",
        Amount: 29,
      },
      {
        Continent: "Asia",
        date: "2021-01-01",
        Dimension: "Japan",
        Amount: 25,
      },
      {
        Continent: "Asia",
        date: "2021-01-01",
        Dimension: "Others",
        Amount: 15,
      },
    
      {
        Continent: "Asia",
        date: "2022-01-01",
        Dimension: "China",
        Amount: 30,
      },
      ,
      {
        Continent: "Asia",
        date: "2022-01-01",
        Dimension: "Nepal",
        Amount: 13,
      },
      {
        Continent: "Asia",
        date: "2022-01-01",
        Dimension: "India",
        Amount: 27,
      },
      {
        Continent: "Asia",
        date: "2022-01-01",
        Dimension: "Japan",
        Amount: 25,
      },
      {
        Continent: "Asia",
        date: "2022-01-01",
        Dimension: "Others",
        Amount: 5,
      },
    
      {
        Continent: "Asia",
        date: "2023-01-01",
        Dimension: "China",
        Amount: 28,
      },
      ,
      {
        Continent: "Asia",
        date: "2023-01-01",
        Dimension: "Nepal",
        Amount: 12,
      },
      {
        Continent: "Asia",
        date: "2023-01-01",
        Dimension: "India",
        Amount: 28,
      },
      {
        Continent: "Asia",
        date: "2023-01-01",
        Dimension: "Japan",
        Amount: 22,
      },
      {
        Continent: "Asia",
        date: "2023-01-01",
        Dimension: "Others",
        Amount: 10,
      },
]

export default energy_total_sources