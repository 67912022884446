import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

function About() {
    const peru = 50;
    const usa = 18;
    const spain = 10;
    const australia = 22;

    return (
        <section id="about">
            <div className="container">
                <p className="text-center mb-2 wow fadeInUp">
                    <span className="bg-primary text-dark px-2">About Me</span>
                </p>
                <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                    Professional path
                </h2>
                <div className="row">
                    <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
                        <h2 className="text-8 fw-400 mb-3">
                            Hi, I'm
                            <span className="fw-700 border-3 border-primary ml-1">
                  Moises Osorio
                </span>
                        </h2>
                        <p className="text-5 justify-text m-2">
                            Senior Data & Software Engineer with over five years of building
                            analytical processes. Moreover, eight years of extracting,
                            transforming, loading, and processing more than 5,000 inputs in
                            Peru's financial and telecommunications industry. I possess
                            international experience in the United state of America and
                            Spain, where I have taught courses in big data and streaming
                            tools. One of my use cases succeeded was creating an analytical
                            engine for BBVA multinational bank where we processed all
                            products and prepared 60 output variables to predict the next
                            season's sales; further, we improved the data delivery time by
                            90% against the initial.
                        </p>
                        <br></br>
                        <label className="text-4 justify-text fw-700">Country work experience:</label>
                        <ProgressBar>
                            <ProgressBar variant="success" now={50} label={`Peru`}/>
                            <ProgressBar variant="warning" now={10} label={`Spain`}/>
                            <ProgressBar variant="danger" now={18} label={`USA`}/>
                            <ProgressBar variant="info" now={22} label={`Australia`}/>
                        </ProgressBar>
                        <br></br>
                        <label className="text-4 justify-text fw-700">Industry experience:</label>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="success">Telecom & Banking</Badge>
                            <Badge bg="warning">Teaching</Badge>
                            <Badge bg="danger">Health</Badge>
                            <Badge bg="info">Transporting</Badge>
                        </Stack>
                    </div>
                    <div
                        className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
                        data-wow-delay="0.2s"
                    >
                        <div className="featured-box style-4">
                            <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                  <span className="wow heartBeat" data-wow-delay="1.3s">
                    14
                  </span>
                            </div>
                            <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                                Years of <span className="fw-700">Experience</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row gy-3 mt-4">
                    <div className="col-6 col-lg-3 wow fadeInUp">
                        <p className="text-muted fw-500 mb-0">Name:</p>
                        <p className="text-4 text-dark fw-600 mb-0">Moises Osorio</p>
                    </div>
                    <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                        <p className="text-muted fw-500 mb-0">ABN:</p>
                        <p className="text-4 fw-600 mb-0">
                            <a
                                className="link-dark"
                                href="https://abr.business.gov.au/ABN/View?abn=51164789532"
                            >
                                51164789532
                            </a>
                        </p>
                    </div>
                    <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                        <p className="text-muted fw-500 mb-0">Professional Profiles:</p>
                        <p className="text-4 text-dark fw-600 mb-0">
                            Data & Software Engineer
                        </p>
                    </div>
                    <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                        <p className="text-muted fw-500 mb-0">Located:</p>
                        <p className="text-4 text-dark fw-600 mb-0">NSW, Australia.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
