import React, { useMemo, boolean } from "react";
import { CopyBlock } from 'react-code-blocks';
import codeblock from '../data/courses/codeBlocks';


function CodeBlockComponent({ text, language, showLineNumbers, theme }) {

  const finalcodeblock = useMemo(() =>
    codeblock
      .filter((i) => i.id === text))  

  return (
    <CopyBlock
      text={finalcodeblock[0].code}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={theme}
      codeBlock
      customStyle={{ fontSize: '0.75rem', overflowX: 'auto' }}
      // codeStyles={{ overflowWrap: "break-word", overflowY: 'scroll' }}
    />
  );
}

export default CodeBlockComponent;