import React, { useState } from "react";
import "./CoursesPrompt.css";
import coursesdata from "./CoursesData";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Container, Col, Row } from "react-bootstrap";
import pdf from "../../data/courses/Spark_SQL.pdf";
import CodeBlockComponent from "../../components/CodeBlockComponent";
import { dracula } from "react-code-blocks";


// import useScrollTo from '../../charts/ScrollToTop';

function CoursesPrompt() {
  const parm = useLocation();
  let index = parm.state == null ? 0 : parm.state;
  let object = coursesdata[index];

  const lineNumbers = useState(true);

  // Function will execute on click of button
  const onDownloadSyllabus = () => {
    fetch(pdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);

        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Spark_SQL2.pdf";
        alink.click();
      });
    });
  };

  return (
    <div className="course-prompt-background">
      <div className="p-4 p-md-5 mb-4 course-title">
        <div className="container title-prompt">
          <div className="row">
            <div className="col-lg-6 px-0 title-prompt">
              <h1 className="display-4 fst-italic">{object.title}</h1>
              <p className="lead my-3">{object.content}</p>
              {/* <div className="col-lg-6 px-0">
                <Button
                  variant="primary"
                  href="https://www.udacity.com/course/data-engineer-nanodegree--nd027"
                >
                  Enrroll Now!
                </Button>
              </div> */}
            </div>
            <div className="col-lg-6 px-0 title-prompt">
              <img
                width="80%"
                height="80%"
                alt=""
                className="image-title-prompt"
                src={object.image_title_prompt}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="course-target-detail">
          <div className="course-target mb-4">
            <div className="row g-0 border rounded flex-md-row shadow-sm">
              <div className="col d-flex flex-column">
                <strong className="d-inline-block text-center bg-primary">
                  What you'll learn
                </strong>
                <div className="course-target-learn">
                  <ul className="pl-4 pt-4 pr-4 pb-1 course-goal-text-size">
                    <li className="course-goal-li">{object.course_goals[1]}</li>
                    <li className="course-goal-li">{object.course_goals[2]}</li>
                    <li className="course-goal-li">{object.course_goals[3]}</li>
                    <li className="course-goal-li">{object.course_goals[4]}</li>
                    <li className="course-goal-li">{object.course_goals[5]}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="course-detail mb-4">
            <div className="row g-0 border rounded flex-md-row shadow-sm">
              <div className="col d-flex flex-column">
                <strong className="d-inline-block text-center bg-primary">
                  Content Details
                </strong>
                <div className="course-target-learn">
                  <ul className="p-3 course-goal-text-size">
                    <li className="course-detail-li">
                      {object.course_detail.access}
                    </li>
                    <li className="course-detail-li">
                      {object.course_detail.duration}
                    </li>
                    <li className="course-detail-li">
                      {object.course_detail.resources}
                    </li>
                    <li className="course-detail-li">
                      {object.course_detail.total_time}
                    </li>
                  </ul>
                  <div className="d-flex flex-column">
                    <button
                      className="button-tutorial"
                      disabled
                      onClick={onDownloadSyllabus}
                    >
                      In Progressing
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-auto d-none d-lg-block course-prompt-div">
                <img
                  width="220"
                  height="160"
                  alt=""
                  className="course-prompt-img"
                  src={object.image_logo}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="course-content-requirement">
          <div className="course-content">
            <article className="blog-post">
              <div className="border-bottom-indigo">
                <h3 className="display-6 link-body-emphasis mb-1">
                  Primary Content
                </h3>
              </div>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                {object.syllabus.map((course, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <Container>
                        <Row>
                          <Col lg="auto" className="course-title-accordion">
                            {course.title}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="auto" className="course-sub-title-accordion">
                            {course.module} &#x2728; {course.classes}
                          </Col>
                        </Row>
                      </Container>
                    </Accordion.Header>
                    <ul>
                      {course.content.map((content, index) => (
                        <Accordion.Body key={index}>
                          <li>&#x2728; {content.topic}</li>
                          <CodeBlockComponent
                            text={content.code}
                            language="python"
                            showLineNumberst={lineNumbers}
                            theme={dracula}
                            />
                          
                        </Accordion.Body>
                      ))}
                    </ul>
                  </Accordion.Item>
                ))}
              </Accordion>
            </article>
          </div>
          <div className="course-requirement">
            <div className="p-2">
              <div className="p-4 mb-3 bg-body-tertiary bg-primary rounded">
                <strong className="d-inline-block mb-2 text-center bg-primary">
                  Requirements
                </strong>
                <ul className="course-goal-text-size">
                  <li className="course-requirement-li">
                    {object.course_requirements.knowledge}
                  </li>
                  <li className="course-requirement-li">
                    {object.course_requirements.profiles}
                  </li>
                  <li className="course-requirement-li">
                    {object.course_requirements.tools}
                  </li>
                  <li className="course-requirement-li">
                    {object.course_requirements.localhost}
                  </li>
                  <li className="course-requirement-li">
                    {object.course_requirements.accounts}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursesPrompt;
