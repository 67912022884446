import React, { useEffect, useRef } from "react";
import { select, pie, arc, scaleOrdinal } from "d3";


const DonutChart = (props) => {
  const ref = useRef();

  const data = props.data;
  const title = props.title;
  const year = props.year;

  console.log(year);
  console.log(data);

  // Specify the chart’s dimensions.
  const width = 628;
  const height = Math.min(width, 320);
  const marginTop = -130;
  const marginRight = 20;
  const marginBottom = 30;
  const marginLeft = -310;

  
  const areaColors = ["#9abcde", "#789abc", "#2980b9", "#345678", "#123456"];

  useEffect(() => {
    const radius = Math.min(width, height) / 3;

    const arcChart = arc()
      .innerRadius(radius * 0.67)
      .outerRadius(radius - 1);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    const pieChart = pie()
      .value(function (d) {
        return d.Amount;
      })
      .sort(null);

    const color = scaleOrdinal()
      .domain(data.map((d) => d.Dimension))
      .range(areaColors);

    const svg = select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

    //clean the chart before painting
    svg.selectAll("*").remove();

    svg
      .selectAll("path")
      .data(pieChart(data))
      .enter()
      .append("path")
      .attr("d", arcChart)
      .attr("fill", function (d, i) {
        return color(d.data.Dimension);
      });

    svg
      .append("text")
      .attr(
        "transform",
        "translate(" + marginLeft + " ," + (marginTop) + ")"
      )
      .attr("text-anchor", "start")
      .style("font-weight", "bold")
      .style("font-size", "15px")
      .text(title);

    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(pieChart(data))
      .enter()
      .append("polyline")
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function (d) {
        var posA = arcChart.centroid(d); // line insertion in the slice
        var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        var posC = outerArc.centroid(d); // Label position = almost the same as posB
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll("allLabels")
      .data(pieChart(data))
      .join("text")
      .text((d) => d.data.Dimension)
      .style("font-size", "15px")
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });
  }, [data, width, height]);

  return <svg ref={ref}></svg>;
};

export default DonutChart;
