import React from "react";
import Carousel from "react-bootstrap/Carousel";

function Testimonial() {
  return (
    <section id="testimonial">
      <div className="container">
        <p className="text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">
            Clients & Collegues Speak
          </span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What some of my clients & collegues say
        </h2>
        <Carousel indicators={false} fade={true} >
          <Carousel.Item>
              <p className="text-5 text-white justify-text">
                "Moisés, has a facility for teamwork, with a lot of seniority in
                data projects, always seeks to achieve the objectives, good
                ability to resolve blockades. Surprise the client with the
                results.”
              </p>
            <div className="carousel-caption-items">
              <Carousel.Caption>
                <img
                  className="img-fluid d-inline-block w-auto rounded-circle shadow"
                  src={require("../../images/testimonial/helmer.jpg")}
                  alt=""
                />
                <strong className="d-block text-3 fw-600 text-white">
                  Helmer Mucha
                </strong>
                <span className="text-light">Senior Manager Tech at BBVA</span>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-5 text-white justify-text">
              “The time that I have worked with Moisés has shown proactivity,
              responsibility and teamwork in the assigned projects.”
            </p>
            <Carousel.Caption>
              <img
                className="img-fluid d-inline-block w-auto rounded-circle shadow"
                src={require("../../images/testimonial/yeny.jpg")}
                alt=""
              />
              <strong className="d-block text-3 fw-500 text-white">
                Yeny Ramos
              </strong>
              <span className="text-light">
                Data Engineer Senior at Scotiabank
              </span>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-5 text-white justify-text">
              “Excellent professional and person. Always willing to help in
              favor of the business. With their help we were able to optimize
              various valuable processes for the Personal Banking Risks team.”
            </p>
            <Carousel.Caption>
              <img
                className="img-fluid d-inline-block w-auto rounded-circle shadow"
                src={require("../../images/testimonial/eduardo.jpg")}
                alt=""
              />
              <strong className="d-block text-3 fw-500 text-white">
                Eduardo Gabriel Meza Chavez
              </strong>
              <span className="text-light">
                Data Analytics Manager | Product Owner at Interbank
              </span>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-5 text-white justify-text">
              “Excellent professional focused on the goal. We implemented the
              big data platform at INTERBANK, which continues to meet the demand
              of the Analytics areas. Totally committed and always looking for
              new ways to solve problems.”
            </p>
            <Carousel.Caption>
              <img
                className="img-fluid d-inline-block w-auto rounded-circle shadow"
                src={require("../../images/testimonial/chumioque.jpg")}
                alt=""
              />
              <strong className="d-block text-3 fw-500 text-white">
                Antonio Chumioque
              </strong>
              <span className="text-light">Big Data Manager at Interbank</span>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default Testimonial;
