import React from "react";
import "./Analytics.css";
import Chart from "../../charts/RectChart";
import { Container, Row, Col } from "react-bootstrap";

function Streaming() {
  const data = [20, 30, 40, 50, 60];
  return (
    <Container fluid className="">
      <Row className="sport-analytics-grid">
        <div className="sport-analytics-prompt alineacion">
          <div className="border">
          <h1>prompt</h1>
          <Chart data={data} />
          </div>
        </div>
        <div className="energy_total_per_source">
          <h1>pie</h1>
          <Chart data={data} />
        </div>
        <div className="energy_total_per_year">
          <h1>lines</h1>
          <Chart data={data} />
        </div>
        <div className="energy_per_source">
          <h1>bar</h1>
          <Chart data={data} />
        </div>
        <div className="energy_per_country">
          <h1>candel</h1>
          <Chart data={data} />
        </div>
        <div className="sport-analytics-table alineacion">
          <h1>table</h1>
          <Chart data={data} />
        </div>
      </Row>
    </Container>
  );
}

export default Streaming;
