import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DestinationMoney from "../pages/philantrophy/DestinationMoney";
import History from '../pages/hobbies/History';
import CoursesPrompt from "../pages/courses/CoursesPrompt";
import Dashboard from "../pages/analytics/Dashboard";
import Streaming from '../pages/analytics/Streaming';
import MainLanding from "../pages/landing/MainLanding";
import ResponsiveAppBar from "./ResponsiveAppBar";

function AppRouter() {
  return (
    <BrowserRouter>
      <div>
        <div className="">
          <ResponsiveAppBar />
        </div>
        <div>
          <Routes>
            <Route path="/" element={<MainLanding />} />
            <Route path="/coursesprompt" element={<CoursesPrompt />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/streaming" element={<Streaming />} />
            <Route path="/destinationmoney" element={<DestinationMoney />} />
            <Route path="/hobbies" element={<History />}/>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default AppRouter;
