import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import coursesdata from "../courses/CoursesData";
import analyticsdata from "../analytics/AnalyticsData";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import portfolioData from "../courses/CoursesData";

function Portfolio() {
    const navigate = useNavigate();
    function handleClick(event, data) {
        event.preventDefault();
        if (data === 1) {
            navigate("/dashboard", { state: data });
        } else if (data === 2) {
            navigate("/streaming", { state: data });
        } else {
            navigate("/coursesprompt", { state: data });
        }
    }

    return (
        <div className="container">
            <p className="text-center mb-2 wow fadeInUp">
                <span className="bg-primary text-dark px-2">Portfolio</span>
            </p>
            <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                Courses & Examples
            </h2>
            <div className="card-grid">
            <div className="researching-grid">
                {portfolioData.map((item, index) => (
                    <div className="researching-item">
                        <article className="researching-card">
                            <img
                                className="card__background"
                                src={item.image}
                                alt=""
                                width="1920"
                                height="2193"
                            />
                            <div className="card__content | flow">
                                <div className="card__content--container | flow">
                                    <h2 id="researching-h2" className="card__title">{item.title}</h2>
                                    <p id="researching-p" className="card__description">
                                        {item.summary_content}
                                    </p>
                                </div>
                                <button className="card__button"
                                        disabled={!item.button_portfolio_status}
                                        onClick={(event) => handleClick(event, index)}>{item.button_portfolio_description}</button>
                            </div>
                        </article>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
  // const navigate = useNavigate();
  //
  // function handleClick(event, data) {
  //   event.preventDefault();
  //   // window.scrollTo({top: 0});
  //   if (data === 1) {
  //     navigate("/dashboard", { state: data });
  //   } else if (data === 2) {
  //     navigate("/streaming", { state: data });
  //   } else {
  //     navigate("/coursesprompt", { state: data });
  //   }
  // }
  //
  // return (
  //   <Container className="portfolio">
  //     <p className="text-center mb-2 wow fadeInUp">
  //       <span className="bg-primary text-dark px-2">Portfolio</span>
  //     </p>
  //     <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
  //       Courses to enrroll
  //     </h2>
  //
  //     <Row xs={1} sm={1} md={1} lg={1} xl={2} className="g-4">
  //       {coursesdata.map((course, index) => (
  //         <Col key={index}>
  //           <Card className="portfolio-card">
  //             <div className="portfolio-card-image">
  //               <Card.Img variant="top" className="image_courses" src={course.image} />
  //             </div>
  //             <div className="portfolio-card-body">
  //               <Card.Body>
  //                 <Stack className="stack-badge" direction="horizontal" gap={2}>
  //                   {course.programming.map((language, index) => (
  //                     <Badge key={index} pill bg="secondary">
  //                       {language.language}
  //                     </Badge>
  //                   ))}
  //                 </Stack>
  //                 <Card.Title className="title-card">{course.title}</Card.Title>
  //                 <Card.Text>{course.sumary_content}</Card.Text>
  //                 <Button
  //                   variant="primary"
  //                   disabled={course.button_porfolio_status ? false : true}
  //                   onClick={(event) => handleClick(event, index)}
  //                 >
  //                   {course.button_porfolio_status
  //                     ? "More Details!"
  //                     : "In Progress!"}
  //                 </Button>
  //               </Card.Body>
  //             </div>
  //             <div className="portfolio-card-detail">
  //               <ul>
  //                 <li className="portfolio-li">8.5 video hours</li>
  //                 <li className="portfolio-li">14 home works</li>
  //                 <li className="portfolio-li">full practice</li>
  //                 <li className="portfolio-li">$ 20.00</li>
  //               </ul>
  //             </div>
  //           </Card>
  //         </Col>
  //       ))}
  //     </Row>
  //
  //     <h2 className="text-10 fw-600 text-center m-5 wow fadeInUp">
  //       Dashboards & Streaming
  //     </h2>
  //     <Row xs={1} sm={1} md={1} lg={1} xl={2} className="g-4">
  //       {analyticsdata.map((chart, index) => (
  //         <Col key={index}>
  //           <Card className="portfolio-card">
  //             <div className="portfolio-analytics-image">
  //               <Card.Img variant="top" className="image_analytics" src={chart.image} />
  //             </div>
  //             <div className="portfolio-card-body">
  //               <Card.Body>
  //                 <Stack className="stack-badge" direction="horizontal" gap={2}>
  //                   {chart.programming.map((language, index) => (
  //                     <Badge key={index} pill bg="secondary">
  //                       {language.language}
  //                     </Badge>
  //                   ))}
  //                 </Stack>
  //                 <Card.Title className="title-card">{chart.title}</Card.Title>
  //                 <Card.Text>{chart.sumary_content}</Card.Text>
  //                 <Button
  //                   variant="primary"
  //                   disabled={chart.button_porfolio_status ? false : true}
  //                   onClick={(event) => handleClick(event, chart.id)}
  //                 >
  //                   {chart.button_porfolio_status
  //                     ? "Enjoy it!"
  //                     : "In Progress!"}
  //                 </Button>
  //               </Card.Body>
  //             </div>
  //             <div className="portfolio-card-detail">
  //               <ul>
  //                 {chart.dashboard_details.map((item, index) => (
  //                   <li key={index} className="portfolio-li">
  //                     {item.description}
  //                   </li>
  //                 ))}
  //               </ul>
  //             </div>
  //           </Card>
  //         </Col>
  //       ))}
  //     </Row>
  //   </Container>
  // );
}

export default Portfolio;
