import React from "react";
import useExternalScripts from "../../hooks/UseScript";
import linkedInLogo from '../../images/landing/LI-Logo.png';
import githubLogo from '../../images/landing/GitHub_Logo.png';


function WorkingMe() {
  useExternalScripts("hhttps://platform.linkedin.com/badges/js/profile.js");
  return (
    <div>
      <section>
        <div className="hero-wrap">
          <div className="hero-mask opacity-8 bg-dark"></div>
          <div className="hero-bg parallax working-me-image"></div>
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp" >
              <h2 className="text-10 fw-600 text-white mb-5">
                Interested in working with me?
              </h2>
              <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
              <a
                href="https://www.linkedin.com/in/moises-o-a5967872/"
                className="btn btn-primary rounded-2 wow rubberBand"
                target="_blank"
                data-wow-delay="1s"
                rel="noreferrer"
              >
                View my <img src={linkedInLogo} alt="LinkedIn" style={{maxWidth: '100px', maxHeight: '30px'}}/>
              </a>
              <a
                href="https://github.com/moisesosorio"
                className="btn btn-primary rounded-2 wow rubberBand"
                target="_blank"
                data-wow-delay="1s"
                rel="noreferrer"
              >
                View my <img src={githubLogo} alt="LinkedIn" style={{maxWidth: '100px', maxHeight: '27px'}}/>
              </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WorkingMe;
