import { useEffect } from 'react'

const useExternalScripts = url =>{
  useEffect(() => {
    const script = document.createElement("script");

    script.url = url;
    script.async = true;

    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useExternalScripts;