import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const RectChart = (props) => {
  const ref = useRef();

  const data = props.data;
  const title = props.title;
  const year = props.year;

  const width = 628;
  const height = 350;
  const marginTop = 50;
  const marginRight = 5;
  const marginBottom = 30;
  const marginLeft = 40;

  // const barColors = ["#9abcde", "#789abc", "#2980b9", "#345678", "#123456"];
  const barColors = [
    "#2E8B57",
    "#345678",
    "#838996",
    "#def012"
  ];

  useEffect(() => {
    // Sort the data by Amount in ascending order
    data.sort((a, b) => d3.ascending(a.Amount, b.Amount));

    const x = d3
      .scaleBand()
      .domain(
        d3.groupSort(
          data,
          ([d]) => d.Amount,
          (d) => d.Dimension
        )
      ) // descending frequency
      .range([marginLeft, width - marginRight])
      .padding(0.1);

    // Declare the y (vertical position) scale.
    const y = d3
      .scaleLinear()
      // .domain([0, d3.max(data, (d) => d.Amount)])
      .domain([0, 100])
      .range([height - marginBottom, marginTop]);

    const y2 = d3.scaleLinear()
      .domain([0, 80]) // This is the range of values for the secondary y-axis
      .range([height - marginBottom, marginTop]); // This should be the same as your primary y-axis to align both axes

    const yAxisRight = d3.axisRight(y2);

    const forecast = d3
      .line()
      .x((d) => x(d.Dimension))
      .y((d) => y(d.Forecast));


    // Create the SVG container.
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("style", "max-width: 100%; height: auto;");

    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.Amount))
      .range(barColors);

    //clean the chart before painting
    svg.selectAll("*").remove();

    // Add a rect for each bar.
    svg
      .append("g")
      .selectAll()
      .data(data)
      .join("rect")
      .attr("x", (d) => x(d.Dimension))
      .attr("y", (d) => y(d.Amount))
      .attr("height", (d) => y(0) - y(d.Amount))
      .attr("fill", (d) => color(d.Amount))
      .attr("width", x.bandwidth());

    // Add the x-axis and label.
    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));

    // Add the y-axis and label, and remove the domain line.
    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).tickFormat((y) => y.toFixed()))
      .call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1)
      )
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .append("text")
          .attr("x", -marginLeft)
          .attr("y", 25)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .style("font-weight", "bold")
          .style("font-size", "15px")
          .text(title)
      );

    // Add a text for each bar.
    svg
      .append("g")
      .selectAll()
      .data(data)
      .join("text")
      .attr("x", (d) => x(d.Dimension) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.Amount) - 5)
      .attr("text-anchor", "middle")
      .text((d) => d.Amount);

    svg.append("g")
      .attr("transform", `translate(${width - marginRight},0)`)
      .call(yAxisRight);

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 3)
      .attr("d", forecast);

  }, [data, width, height]);

  return <svg ref={ref}></svg>;
};

export default RectChart;
