import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const StackRectChart = (props) => {
  const ref = useRef();

  const data = props.data;
  const title = props.title;
  const year = props.year;

  const width = 628;
  const height = 350;
  const marginTop = 50;
  const marginRight = 5;
  const marginBottom = 30;
  const marginLeft = 40;

  // const barColors = ["#123456", "#9abcde", "#789abc", "#def012", "#345678"];
  const barColors = [
    "#2E8B57",
    "#345678",
    "#838996",
    "#def012"
  ];

  useEffect(() => {
    // Determine the series that need to be stacked.
    const series = d3
      .stack()
      .keys(d3.union(data.map((d) => d.Dimension))) // distinct series keys, in input order
      .value(([, D], key) => D.get(key).Amount)(
      // get value for each series key and stack
      d3.index(
        data,
        (d) => d.Country,
        (d) => d.Dimension
      )
    ); // group by stack then series key

    // Prepare the scales for positional and color encodings.
    const x = d3
      .scaleBand()
      .domain(
        d3.groupSort(
          data,
          (D) => -d3.sum(D, (d) => d.Amount),
          (d) => d.Country
        )
      )
      .range([marginLeft, width - marginRight])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      // .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
      .domain([0, 100])
      .rangeRound([height - marginBottom, marginTop]);

    const color = d3
      .scaleOrdinal()
      .domain(series.map((d) => d.key))
      .range(barColors)
      .unknown("#ccc");

    // A function to format the value in the tooltip.
    const formatValue = (x) => (isNaN(x) ? "N/A" : x.toLocaleString("en"));

    // Create the SVG container.
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto;");

    //clean the chart before painting
    svg.selectAll("*").remove();

    // Append a group for each series, and a rect for each element in the series.
    svg
      .append("g")
      .selectAll()
      .data(series)
      .join("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((D) => D.map((d) => ((d.key = D.key), d)))
      .join("rect")
      .attr("x", (d) => x(d.data[0]))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth())
      .append("title")
      .text(
        (d) =>
          `${d.data[0]} ${d.key}\n${formatValue(d.data[1].get(d.key).Amount)}`
      );

    // Append the horizontal axis.
    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .call((g) => g.selectAll(".domain").remove());

    // Append the vertical axis.
    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).ticks(null, "s"))
      .call((g) => g.selectAll(".domain").remove())
      .call((g) =>
        g
          .append("text")
          .attr("x", -marginLeft)
          .attr("y", 25)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .style("font-weight", "bold")
          .style("font-size", "15px")
          .text(title)
      );

    // Add the legend
    const legend = svg
      .selectAll(".legend")
      .data(series)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${i * 73},0)`); // Move the legend to the right

    // position of the text element of the legend
    const legendText = legend
      .append("text")
      .attr("x", 270)
      .attr("y", 27)
      .style("text-anchor", "start")
      .style("font-size", "10px")
      .text((d) => d.key);

    // position of the rect element of the legend
    legend
      .append("rect")
      .attr("x", (d, i) => 270 + legendText.nodes()[i].getBBox().width + 3) // Add 10 for some padding
      .attr("y", 20)
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", (d) => color(d.key));

    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));
  }, [data, width, height]);

  return <svg ref={ref}></svg>;
};

export default StackRectChart;
