import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import "../pages/landing/Landing.css";
import "./Components.css";
import { HashLink as Link } from "react-router-hash-link";


function ResponsiveAppBar() {
  const clasTextWhite = "white-text-nav";
  const clasTextBlack = "black-text-nav";
  let classNameText = '';
  const offCanvasElement = useRef();
  const closeOffCanvas = () => {
  const offCanvasState = offCanvasElement.current.backdrop;
    if(offCanvasState){
          offCanvasElement.current.backdrop.click();
          // offCanvasElement.current.
    }
  };

  function temp (){

  }

  return (
    <Navbar expand="lg" key="lg" className="nav-bar-template" collapseOnSelect>
      <Container>
        <Navbar.Brand className="nav-brand">
          <img
            src={require("../images/components/iconM.png")}
            width="30"
            height="30"
            className="icon-nav"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="navbar-toggler"
        />
        <Navbar.Offcanvas id="responsive-navbar-nav" className="nav-bar-collapse" placement="end" ref={offCanvasElement}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className="row"> 
                <div className="col-4">
                  <img
                    src={require("../images/components/iconM.png")}
                    width="30"
                    height="30"
                    className="icon-nav center-content"
                    alt=""
                  />
                </div>
                <div className="col-8 title-offcanvas">Content</div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {/*{*/}
              {/*  (()=> {*/}
              {/*    if (1===1)*/}
              {/*    return classNameText = clasTextWhite*/}
              {/*    else*/}
              {/*    return classNameText = clasTextBlack*/}
              {/*  })()*/}
              {/*}*/}
              <Nav.Link as={Link} to="/#about" className="text-nav" onClick={closeOffCanvas}>
                About Me
              </Nav.Link>
              <Nav.Link as={Link} to="/#portfolio" className="text-nav" onClick={closeOffCanvas}>
                Portfolio
              </Nav.Link>
              <Nav.Link as={Link} to="/#testimonial" className="text-nav" onClick={closeOffCanvas}>
                Client Speak
              </Nav.Link>
              <Nav.Link as={Link} to="/#contact" className="text-nav" onClick={closeOffCanvas}>
                Contact Me!
              </Nav.Link>
              <Nav.Link
                as={Link}
                disabled
                to="/history"
                className="text-nav"
              >
                Hobbies
              </Nav.Link>
              <Nav.Link
                as={Link}
                disabled
                to="/destinationmoney"
                className="text-nav"
              >
                Philantrophy
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
export default ResponsiveAppBar;
