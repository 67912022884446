const energy_per_source = [
  {
    Country: "Egypt",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 3,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 2,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 35,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 50,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 35,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 4,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 46,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 8,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 57,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 4,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 11,
    Continent: "Africa",
  },

  {
    Country: "Egypt",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 6,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 29,
    Continent: "Africa",
  },
  {
    Country: "Egypt",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 13,
    Continent: "Africa",
  },
  {
    Country: "South Africa",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 12,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Africa",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 17,
    Continent: "Africa",
  },
  {
    Country: "Congo",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 3,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Africa",
  },
  {
    Country: "Nigeria",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Africa",
  },

  {
    Country: "Peru",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 3,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 2,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 35,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 50,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 35,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 4,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 46,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 8,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 57,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 4,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 11,
    Continent: "America",
  },

  {
    Country: "Peru",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 6,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 29,
    Continent: "America",
  },
  {
    Country: "Peru",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 13,
    Continent: "America",
  },
  {
    Country: "Brazil",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 12,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 17,
    Continent: "America",
  },
  {
    Country: "Argentina",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 3,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "America",
  },
  {
    Country: "Chile",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "America",
  },
  {
    Country: "Germany",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 3,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 2,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 35,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 50,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 35,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 4,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 46,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 8,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 57,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 4,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 11,
    Continent: "Europe",
  },

  {
    Country: "Germany",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 6,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 29,
    Continent: "Europe",
  },
  {
    Country: "Germany",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 13,
    Continent: "Europe",
  },
  {
    Country: "Netherlands",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 12,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 17,
    Continent: "Europe",
  },
  {
    Country: "Sweeden",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 3,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Europe",
  },
  {
    Country: "Spain",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Europe",
  },
  {
    Country: "Nepal",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 3,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 2,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 35,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 50,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 35,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 4,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 46,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 8,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 57,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 4,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 11,
    Continent: "Asia",
  },

  {
    Country: "Nepal",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 6,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 29,
    Continent: "Asia",
  },
  {
    Country: "Nepal",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 13,
    Continent: "Asia",
  },
  {
    Country: "China",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 12,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "Others",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 17,
    Continent: "Asia",
  },
  {
    Country: "India",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 3,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Asia",
  },
  {
    Country: "Japan",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Asia",
  },
  {
    Country: "New Caledonia",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 3,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 2,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 35,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 50,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2021-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2021-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2021-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2021-01-01",
    Dimension: "Plant",
    Amount: 35,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 4,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 46,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 23,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 22,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 8,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 57,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 20,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2022-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2022-01-01",
    Dimension: "Water",
    Amount: 4,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2022-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2022-01-01",
    Dimension: "Plant",
    Amount: 11,
    Continent: "Oceania",
  },

  {
    Country: "New Caledonia",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 6,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 29,
    Continent: "Oceania",
  },
  {
    Country: "New Caledonia",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 60,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 45,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 30,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 13,
    Continent: "Oceania",
  },
  {
    Country: "New Zeland",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 12,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 10,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 40,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Oceania",
  },
  {
    Country: "Australia",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 40,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 10,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 17,
    Continent: "Oceania",
  },
  {
    Country: "Fiji",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 3,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2023-01-01",
    Dimension: "Solar",
    Amount: 70,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2023-01-01",
    Dimension: "Water",
    Amount: 5,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2023-01-01",
    Dimension: "Wind",
    Amount: 15,
    Continent: "Oceania",
  },
  {
    Country: "PNG",
    date: "2023-01-01",
    Dimension: "Plant",
    Amount: 10,
    Continent: "Oceania",
  },
];

export default energy_per_source;
