import React from "react";

function Home() {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark"></div>
        <div className="hero-bg parallax home-image"></div>
        <div className="hero-content d-flex fullscreen-with-header py-5">
          <div className="container my-auto py-4">
            <div className="row">
              <div className="col-lg-12 text-center order-1 order-lg-0">
                <h1 className="text-8 text-white fw-400 mb-0 text-uppercase">
                  Hi, I'm a
                </h1>
                <div>
                  <h1 className="home-title">DATA SOFTWARE ENGINEER</h1>
                </div>
                <p className="text-5 text-light">
                  based in New South Wells, Australia.
                </p>
                <a
                  href="#portfolio"
                  className="btn btn-primary fw-600 rounded-5 smooth-scroll mt-3"
                >
                  View My Works
                </a>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-light smooth-scroll"
          >
            <span className="animated">
              <i className="fas fa-arrow-down"></i>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Home;
