const energy_total_continents = [
    {
      Country: "Africa",
      date: new Date("1965-01-01"),
      Renewables_percentage: 5.740281
    },
    {
      Country: "Africa",
      date: new Date("1966-01-01"),
      Renewables_percentage: 6.113969
    },
    {
      Country: "Africa",
      date: new Date("1967-01-01"),
      Renewables_percentage: 6.31658
    },
    {
      Country: "Africa",
      date: new Date("1968-01-01"),
      Renewables_percentage: 6.994845
    },
    {
      Country: "Africa",
      date: new Date("1969-01-01"),
      Renewables_percentage: 7.943916
    },
    {
      Country: "Africa",
      date: new Date("1970-01-01"),
      Renewables_percentage: 9.148895
    },
    {
      Country: "Africa",
      date: new Date("1971-01-01"),
      Renewables_percentage: 8.124994
    },
    {
      Country: "Africa",
      date: new Date("1972-01-01"),
      Renewables_percentage: 8.747272
    },
    {
      Country: "Africa",
      date: new Date("1973-01-01"),
      Renewables_percentage: 8.54162
    },
    {
      Country: "Africa",
      date: new Date("1974-01-01"),
      Renewables_percentage: 9.252387
    },
    {
      Country: "Africa",
      date: new Date("1975-01-01"),
      Renewables_percentage: 9.228769
    },
    {
      Country: "Africa",
      date: new Date("1976-01-01"),
      Renewables_percentage: 9.41638
    },
    {
      Country: "Africa",
      date: new Date("1977-01-01"),
      Renewables_percentage: 9.803094
    },
    {
      Country: "Africa",
      date: new Date("1978-01-01"),
      Renewables_percentage: 9.518462
    },
    {
      Country: "Africa",
      date: new Date("1979-01-01"),
      Renewables_percentage: 9.349527
    },
    {
      Country: "Africa",
      date: new Date("1980-01-01"),
      Renewables_percentage: 8.481442
    },
    {
      Country: "Africa",
      date: new Date("1981-01-01"),
      Renewables_percentage: 7.852752
    },
    {
      Country: "Africa",
      date: new Date("1982-01-01"),
      Renewables_percentage: 7.373427
    },
    {
      Country: "Africa",
      date: new Date("1983-01-01"),
      Renewables_percentage: 6.456112
    },
    {
      Country: "Africa",
      date: new Date("1984-01-01"),
      Renewables_percentage: 6.158577
    },
    {
      Country: "Africa",
      date: new Date("1985-01-01"),
      Renewables_percentage: 6.579342
    },
    {
      Country: "Africa",
      date: new Date("1986-01-01"),
      Renewables_percentage: 6.715967
    },
    {
      Country: "Africa",
      date: new Date("1987-01-01"),
      Renewables_percentage: 6.243963
    },
    {
      Country: "Africa",
      date: new Date("1988-01-01"),
      Renewables_percentage: 6.362116
    },
    {
      Country: "Africa",
      date: new Date("1989-01-01"),
      Renewables_percentage: 6.66546
    },
    {
      Country: "Africa",
      date: new Date("1990-01-01"),
      Renewables_percentage: 6.586325
    },
    {
      Country: "Africa",
      date: new Date("1991-01-01"),
      Renewables_percentage: 6.981655
    },
    {
      Country: "Africa",
      date: new Date("1992-01-01"),
      Renewables_percentage: 6.570122
    },
    {
      Country: "Africa",
      date: new Date("1993-01-01"),
      Renewables_percentage: 6.432488
    },
    {
      Country: "Africa",
      date: new Date("1994-01-01"),
      Renewables_percentage: 6.30997
    },
    {
      Country: "Africa",
      date: new Date("1995-01-01"),
      Renewables_percentage: 6.308814
    },
    {
      Country: "Africa",
      date: new Date("1996-01-01"),
      Renewables_percentage: 6.435814
    },
    {
      Country: "Africa",
      date: new Date("1997-01-01"),
      Renewables_percentage: 6.468487
    },
    {
      Country: "Africa",
      date: new Date("1998-01-01"),
      Renewables_percentage: 6.677105
    },
    {
      Country: "Africa",
      date: new Date("1999-01-01"),
      Renewables_percentage: 6.859676
    },
    {
      Country: "Africa",
      date: new Date("2000-01-01"),
      Renewables_percentage: 7.20691
    },
    {
      Country: "Africa",
      date: new Date("2001-01-01"),
      Renewables_percentage: 7.440533
    },
    {
      Country: "Africa",
      date: new Date("2002-01-01"),
      Renewables_percentage: 7.675384
    },
    {
      Country: "Africa",
      date: new Date("2003-01-01"),
      Renewables_percentage: 7.109379
    },
    {
      Country: "Africa",
      date: new Date("2004-01-01"),
      Renewables_percentage: 6.983353
    },
    {
      Country: "Africa",
      date: new Date("2005-01-01"),
      Renewables_percentage: 7.03104
    },
    {
      Country: "Africa",
      date: new Date("2006-01-01"),
      Renewables_percentage: 7.16137
    },
    {
      Country: "Africa",
      date: new Date("2007-01-01"),
      Renewables_percentage: 7.060168
    },
    {
      Country: "Africa",
      date: new Date("2008-01-01"),
      Renewables_percentage: 6.7642
    },
    {
      Country: "Africa",
      date: new Date("2009-01-01"),
      Renewables_percentage: 6.812359
    },
    {
      Country: "Africa",
      date: new Date("2010-01-01"),
      Renewables_percentage: 7.134043
    },
    {
      Country: "Africa",
      date: new Date("2011-01-01"),
      Renewables_percentage: 7.273434
    },
    {
      Country: "Africa",
      date: new Date("2012-01-01"),
      Renewables_percentage: 7.023342
    },
    {
      Country: "Africa",
      date: new Date("2013-01-01"),
      Renewables_percentage: 7.221603
    },
    {
      Country: "Africa",
      date: new Date("2014-01-01"),
      Renewables_percentage: 7.546608
    },
    {
      Country: "Africa",
      date: new Date("2015-01-01"),
      Renewables_percentage: 7.49733
    },
    {
      Country: "Africa",
      date: new Date("2016-01-01"),
      Renewables_percentage: 7.293605
    },
    {
      Country: "Africa",
      date: new Date("2017-01-01"),
      Renewables_percentage: 7.722913
    },
    {
      Country: "Africa",
      date: new Date("2018-01-01"),
      Renewables_percentage: 8.085691
    },
    {
      Country: "Africa",
      date: new Date("2019-01-01"),
      Renewables_percentage: 8.440789
    },
    {
      Country: "Africa",
      date: new Date("2020-01-01"),
      Renewables_percentage: 9.53104
    },
    {
      Country: "Africa",
      date: new Date("2021-01-01"),
      Renewables_percentage: 9.419954
    },
    {
      Country: "Africa",
      date: new Date("2022-01-01"),
      Renewables_percentage: 9.656152
    },
    {
      Country: "Asia",
      date: new Date("1965-01-01"),
      Renewables_percentage: 7.897043
    },
    {
      Country: "Asia",
      date: new Date("1966-01-01"),
      Renewables_percentage: 7.590176
    },
    {
      Country: "Asia",
      date: new Date("1967-01-01"),
      Renewables_percentage: 6.773928
    },
    {
      Country: "Asia",
      date: new Date("1968-01-01"),
      Renewables_percentage: 6.975909
    },
    {
      Country: "Asia",
      date: new Date("1969-01-01"),
      Renewables_percentage: 6.371003
    },
    {
      Country: "Asia",
      date: new Date("1970-01-01"),
      Renewables_percentage: 5.763838
    },
    {
      Country: "Asia",
      date: new Date("1971-01-01"),
      Renewables_percentage: 5.741738
    },
    {
      Country: "Asia",
      date: new Date("1972-01-01"),
      Renewables_percentage: 5.588214
    },
    {
      Country: "Asia",
      date: new Date("1973-01-01"),
      Renewables_percentage: 4.796797
    },
    {
      Country: "Asia",
      date: new Date("1974-01-01"),
      Renewables_percentage: 5.339645
    },
    {
      Country: "Asia",
      date: new Date("1975-01-01"),
      Renewables_percentage: 5.550569
    },
    {
      Country: "Asia",
      date: new Date("1976-01-01"),
      Renewables_percentage: 5.394896
    },
    {
      Country: "Asia",
      date: new Date("1977-01-01"),
      Renewables_percentage: 4.973046
    },
    {
      Country: "Asia",
      date: new Date("1978-01-01"),
      Renewables_percentage: 4.89683
    },
    {
      Country: "Asia",
      date: new Date("1979-01-01"),
      Renewables_percentage: 5.028547
    },
    {
      Country: "Asia",
      date: new Date("1980-01-01"),
      Renewables_percentage: 5.400711
    },
    {
      Country: "Asia",
      date: new Date("1981-01-01"),
      Renewables_percentage: 5.706973
    },
    {
      Country: "Asia",
      date: new Date("1982-01-01"),
      Renewables_percentage: 5.711756
    },
    {
      Country: "Asia",
      date: new Date("1983-01-01"),
      Renewables_percentage: 5.695761
    },
    {
      Country: "Asia",
      date: new Date("1984-01-01"),
      Renewables_percentage: 5.315992
    },
    {
      Country: "Asia",
      date: new Date("1985-01-01"),
      Renewables_percentage: 5.351944
    },
    {
      Country: "Asia",
      date: new Date("1986-01-01"),
      Renewables_percentage: 5.187796
    },
    {
      Country: "Asia",
      date: new Date("1987-01-01"),
      Renewables_percentage: 5.080285
    },
    {
      Country: "Asia",
      date: new Date("1988-01-01"),
      Renewables_percentage: 5.438683
    },
    {
      Country: "Asia",
      date: new Date("1989-01-01"),
      Renewables_percentage: 5.325835
    },
    {
      Country: "Asia",
      date: new Date("1990-01-01"),
      Renewables_percentage: 5.333219
    },
    {
      Country: "Asia",
      date: new Date("1991-01-01"),
      Renewables_percentage: 5.191427
    },
    {
      Country: "Asia",
      date: new Date("1992-01-01"),
      Renewables_percentage: 5.00876
    },
    {
      Country: "Asia",
      date: new Date("1993-01-01"),
      Renewables_percentage: 5.317764
    },
    {
      Country: "Asia",
      date: new Date("1994-01-01"),
      Renewables_percentage: 5.057478
    },
    {
      Country: "Asia",
      date: new Date("1995-01-01"),
      Renewables_percentage: 5.262231
    },
    {
      Country: "Asia",
      date: new Date("1996-01-01"),
      Renewables_percentage: 5.011352
    },
    {
      Country: "Asia",
      date: new Date("1997-01-01"),
      Renewables_percentage: 4.885949
    },
    {
      Country: "Asia",
      date: new Date("1998-01-01"),
      Renewables_percentage: 5.173664
    },
    {
      Country: "Asia",
      date: new Date("1999-01-01"),
      Renewables_percentage: 4.921889
    },
    {
      Country: "Asia",
      date: new Date("2000-01-01"),
      Renewables_percentage: 4.902229
    },
    {
      Country: "Asia",
      date: new Date("2001-01-01"),
      Renewables_percentage: 5.072077
    },
    {
      Country: "Asia",
      date: new Date("2002-01-01"),
      Renewables_percentage: 5.003891
    },
    {
      Country: "Asia",
      date: new Date("2003-01-01"),
      Renewables_percentage: 4.808949
    },
    {
      Country: "Asia",
      date: new Date("2004-01-01"),
      Renewables_percentage: 5.119528
    },
    {
      Country: "Asia",
      date: new Date("2005-01-01"),
      Renewables_percentage: 4.94667
    },
    {
      Country: "Asia",
      date: new Date("2006-01-01"),
      Renewables_percentage: 5.14454
    },
    {
      Country: "Asia",
      date: new Date("2007-01-01"),
      Renewables_percentage: 5.1096
    },
    {
      Country: "Asia",
      date: new Date("2008-01-01"),
      Renewables_percentage: 5.628602
    },
    {
      Country: "Asia",
      date: new Date("2009-01-01"),
      Renewables_percentage: 5.48424
    },
    {
      Country: "Asia",
      date: new Date("2010-01-01"),
      Renewables_percentage: 5.974635
    },
    {
      Country: "Asia",
      date: new Date("2011-01-01"),
      Renewables_percentage: 5.897969
    },
    {
      Country: "Asia",
      date: new Date("2012-01-01"),
      Renewables_percentage: 6.493125
    },
    {
      Country: "Asia",
      date: new Date("2013-01-01"),
      Renewables_percentage: 7.000718
    },
    {
      Country: "Asia",
      date: new Date("2014-01-01"),
      Renewables_percentage: 7.565922
    },
    {
      Country: "Asia",
      date: new Date("2015-01-01"),
      Renewables_percentage: 7.917923
    },
    {
      Country: "Asia",
      date: new Date("2016-01-01"),
      Renewables_percentage: 8.415236
    },
    {
      Country: "Asia",
      date: new Date("2017-01-01"),
      Renewables_percentage: 8.881613
    },
    {
      Country: "Asia",
      date: new Date("2018-01-01"),
      Renewables_percentage: 9.383873
    },
    {
      Country: "Asia",
      date: new Date("2019-01-01"),
      Renewables_percentage: 10.027869
    },
    {
      Country: "Asia",
      date: new Date("2020-01-01"),
      Renewables_percentage: 10.78211
    },
    {
      Country: "Asia",
      date: new Date("2021-01-01"),
      Renewables_percentage: 11.258453
    },
    {
      Country: "Asia",
      date: new Date("2022-01-01"),
      Renewables_percentage: 12.03901
    },
    {
      Country: "Europe",
      date: new Date("1965-01-01"),
      Renewables_percentage: 5.906675
    },
    {
      Country: "Europe",
      date: new Date("1966-01-01"),
      Renewables_percentage: 6.168219
    },
    {
      Country: "Europe",
      date: new Date("1967-01-01"),
      Renewables_percentage: 5.903432
    },
    {
      Country: "Europe",
      date: new Date("1968-01-01"),
      Renewables_percentage: 5.957701
    },
    {
      Country: "Europe",
      date: new Date("1969-01-01"),
      Renewables_percentage: 5.687199
    },
    {
      Country: "Europe",
      date: new Date("1970-01-01"),
      Renewables_percentage: 5.68146
    },
    {
      Country: "Europe",
      date: new Date("1971-01-01"),
      Renewables_percentage: 5.559321
    },
    {
      Country: "Europe",
      date: new Date("1972-01-01"),
      Renewables_percentage: 5.415697
    },
    {
      Country: "Europe",
      date: new Date("1973-01-01"),
      Renewables_percentage: 5.199635
    },
    {
      Country: "Europe",
      date: new Date("1974-01-01"),
      Renewables_percentage: 5.54915
    },
    {
      Country: "Europe",
      date: new Date("1975-01-01"),
      Renewables_percentage: 5.48716
    },
    {
      Country: "Europe",
      date: new Date("1976-01-01"),
      Renewables_percentage: 5.002477
    },
    {
      Country: "Europe",
      date: new Date("1977-01-01"),
      Renewables_percentage: 5.732007
    },
    {
      Country: "Europe",
      date: new Date("1978-01-01"),
      Renewables_percentage: 5.710373
    },
    {
      Country: "Europe",
      date: new Date("1979-01-01"),
      Renewables_percentage: 5.745069
    },
    {
      Country: "Europe",
      date: new Date("1980-01-01"),
      Renewables_percentage: 5.762645
    },
    {
      Country: "Europe",
      date: new Date("1981-01-01"),
      Renewables_percentage: 5.867198
    },
    {
      Country: "Europe",
      date: new Date("1982-01-01"),
      Renewables_percentage: 5.709534
    },
    {
      Country: "Europe",
      date: new Date("1983-01-01"),
      Renewables_percentage: 5.830078
    },
    {
      Country: "Europe",
      date: new Date("1984-01-01"),
      Renewables_percentage: 5.878145
    },
    {
      Country: "Europe",
      date: new Date("1985-01-01"),
      Renewables_percentage: 5.862853
    },
    {
      Country: "Europe",
      date: new Date("1986-01-01"),
      Renewables_percentage: 5.665528
    },
    {
      Country: "Europe",
      date: new Date("1987-01-01"),
      Renewables_percentage: 5.836272
    },
    {
      Country: "Europe",
      date: new Date("1988-01-01"),
      Renewables_percentage: 5.996327
    },
    {
      Country: "Europe",
      date: new Date("1989-01-01"),
      Renewables_percentage: 5.478756
    },
    {
      Country: "Europe",
      date: new Date("1990-01-01"),
      Renewables_percentage: 5.613285
    },
    {
      Country: "Europe",
      date: new Date("1991-01-01"),
      Renewables_percentage: 5.795919
    },
    {
      Country: "Europe",
      date: new Date("1992-01-01"),
      Renewables_percentage: 6.187997
    },
    {
      Country: "Europe",
      date: new Date("1993-01-01"),
      Renewables_percentage: 6.579752
    },
    {
      Country: "Europe",
      date: new Date("1994-01-01"),
      Renewables_percentage: 6.991816
    },
    {
      Country: "Europe",
      date: new Date("1995-01-01"),
      Renewables_percentage: 7.071921
    },
    {
      Country: "Europe",
      date: new Date("1996-01-01"),
      Renewables_percentage: 6.619829
    },
    {
      Country: "Europe",
      date: new Date("1997-01-01"),
      Renewables_percentage: 7.03665
    },
    {
      Country: "Europe",
      date: new Date("1998-01-01"),
      Renewables_percentage: 7.31368
    },
    {
      Country: "Europe",
      date: new Date("1999-01-01"),
      Renewables_percentage: 7.507233
    },
    {
      Country: "Europe",
      date: new Date("2000-01-01"),
      Renewables_percentage: 7.798537
    },
    {
      Country: "Europe",
      date: new Date("2001-01-01"),
      Renewables_percentage: 7.827453
    },
    {
      Country: "Europe",
      date: new Date("2002-01-01"),
      Renewables_percentage: 7.34533
    },
    {
      Country: "Europe",
      date: new Date("2003-01-01"),
      Renewables_percentage: 6.958901
    },
    {
      Country: "Europe",
      date: new Date("2004-01-01"),
      Renewables_percentage: 7.534636
    },
    {
      Country: "Europe",
      date: new Date("2005-01-01"),
      Renewables_percentage: 7.765187
    },
    {
      Country: "Europe",
      date: new Date("2006-01-01"),
      Renewables_percentage: 7.768465
    },
    {
      Country: "Europe",
      date: new Date("2007-01-01"),
      Renewables_percentage: 8.276369
    },
    {
      Country: "Europe",
      date: new Date("2008-01-01"),
      Renewables_percentage: 8.780239
    },
    {
      Country: "Europe",
      date: new Date("2009-01-01"),
      Renewables_percentage: 9.667576
    },
    {
      Country: "Europe",
      date: new Date("2010-01-01"),
      Renewables_percentage: 10.042885
    },
    {
      Country: "Europe",
      date: new Date("2011-01-01"),
      Renewables_percentage: 9.978288
    },
    {
      Country: "Europe",
      date: new Date("2012-01-01"),
      Renewables_percentage: 11.051453
    },
    {
      Country: "Europe",
      date: new Date("2013-01-01"),
      Renewables_percentage: 12.020726
    },
    {
      Country: "Europe",
      date: new Date("2014-01-01"),
      Renewables_percentage: 12.670538
    },
    {
      Country: "Europe",
      date: new Date("2015-01-01"),
      Renewables_percentage: 12.97831
    },
    {
      Country: "Europe",
      date: new Date("2016-01-01"),
      Renewables_percentage: 13.101557
    },
    {
      Country: "Europe",
      date: new Date("2017-01-01"),
      Renewables_percentage: 13.136423
    },
    {
      Country: "Europe",
      date: new Date("2018-01-01"),
      Renewables_percentage: 13.797882
    },
    {
      Country: "Europe",
      date: new Date("2019-01-01"),
      Renewables_percentage: 14.279471
    },
    {
      Country: "Europe",
      date: new Date("2020-01-01"),
      Renewables_percentage: 16.508207
    },
    {
      Country: "Europe",
      date: new Date("2021-01-01"),
      Renewables_percentage: 15.866847
    },
    {
      Country: "Europe",
      date: new Date("2022-01-01"),
      Renewables_percentage: 16.556276
    },
    {
      Country: "North America",
      date: new Date("1965-01-01"),
      Renewables_percentage: 6.206734
    },
    {
      Country: "North America",
      date: new Date("1966-01-01"),
      Renewables_percentage: 6.148922
    },
    {
      Country: "North America",
      date: new Date("1967-01-01"),
      Renewables_percentage: 6.45024
    },
    {
      Country: "North America",
      date: new Date("1968-01-01"),
      Renewables_percentage: 6.186066
    },
    {
      Country: "North America",
      date: new Date("1969-01-01"),
      Renewables_percentage: 6.523922
    },
    {
      Country: "North America",
      date: new Date("1970-01-01"),
      Renewables_percentage: 6.364854
    },
    {
      Country: "North America",
      date: new Date("1971-01-01"),
      Renewables_percentage: 6.566163
    },
    {
      Country: "North America",
      date: new Date("1972-01-01"),
      Renewables_percentage: 6.607361
    },
    {
      Country: "North America",
      date: new Date("1973-01-01"),
      Renewables_percentage: 6.494107
    },
    {
      Country: "North America",
      date: new Date("1974-01-01"),
      Renewables_percentage: 7.191063
    },
    {
      Country: "North America",
      date: new Date("1975-01-01"),
      Renewables_percentage: 7.209399
    },
    {
      Country: "North America",
      date: new Date("1976-01-01"),
      Renewables_percentage: 6.844898
    },
    {
      Country: "North America",
      date: new Date("1977-01-01"),
      Renewables_percentage: 6.007012
    },
    {
      Country: "North America",
      date: new Date("1978-01-01"),
      Renewables_percentage: 6.737545
    },
    {
      Country: "North America",
      date: new Date("1979-01-01"),
      Renewables_percentage: 6.778191
    },
    {
      Country: "North America",
      date: new Date("1980-01-01"),
      Renewables_percentage: 7.003194
    },
    {
      Country: "North America",
      date: new Date("1981-01-01"),
      Renewables_percentage: 7.26079
    },
    {
      Country: "North America",
      date: new Date("1982-01-01"),
      Renewables_percentage: 8.025408
    },
    {
      Country: "North America",
      date: new Date("1983-01-01"),
      Renewables_percentage: 8.481239
    },
    {
      Country: "North America",
      date: new Date("1984-01-01"),
      Renewables_percentage: 8.282628
    },
    {
      Country: "North America",
      date: new Date("1985-01-01"),
      Renewables_percentage: 8.044863
    },
    {
      Country: "North America",
      date: new Date("1986-01-01"),
      Renewables_percentage: 8.156003
    },
    {
      Country: "North America",
      date: new Date("1987-01-01"),
      Renewables_percentage: 7.523336
    },
    {
      Country: "North America",
      date: new Date("1988-01-01"),
      Renewables_percentage: 6.853338
    },
    {
      Country: "North America",
      date: new Date("1989-01-01"),
      Renewables_percentage: 7.143261
    },
    {
      Country: "North America",
      date: new Date("1990-01-01"),
      Renewables_percentage: 7.549423
    },
    {
      Country: "North America",
      date: new Date("1991-01-01"),
      Renewables_percentage: 7.651944
    },
    {
      Country: "North America",
      date: new Date("1992-01-01"),
      Renewables_percentage: 7.340199
    },
    {
      Country: "North America",
      date: new Date("1993-01-01"),
      Renewables_percentage: 7.589226
    },
    {
      Country: "North America",
      date: new Date("1994-01-01"),
      Renewables_percentage: 7.21988
    },
    {
      Country: "North America",
      date: new Date("1995-01-01"),
      Renewables_percentage: 7.72368
    },
    {
      Country: "North America",
      date: new Date("1996-01-01"),
      Renewables_percentage: 8.056857
    },
    {
      Country: "North America",
      date: new Date("1997-01-01"),
      Renewables_percentage: 7.995956
    },
    {
      Country: "North America",
      date: new Date("1998-01-01"),
      Renewables_percentage: 7.461872
    },
    {
      Country: "North America",
      date: new Date("1999-01-01"),
      Renewables_percentage: 7.514161
    },
    {
      Country: "North America",
      date: new Date("2000-01-01"),
      Renewables_percentage: 7.057672
    },
    {
      Country: "North America",
      date: new Date("2001-01-01"),
      Renewables_percentage: 6.340329
    },
    {
      Country: "North America",
      date: new Date("2002-01-01"),
      Renewables_percentage: 6.864385
    },
    {
      Country: "North America",
      date: new Date("2003-01-01"),
      Renewables_percentage: 6.800338
    },
    {
      Country: "North America",
      date: new Date("2004-01-01"),
      Renewables_percentage: 6.714227
    },
    {
      Country: "North America",
      date: new Date("2005-01-01"),
      Renewables_percentage: 6.974165
    },
    {
      Country: "North America",
      date: new Date("2006-01-01"),
      Renewables_percentage: 7.265713
    },
    {
      Country: "North America",
      date: new Date("2007-01-01"),
      Renewables_percentage: 7.05807
    },
    {
      Country: "North America",
      date: new Date("2008-01-01"),
      Renewables_percentage: 7.771444
    },
    {
      Country: "North America",
      date: new Date("2009-01-01"),
      Renewables_percentage: 8.395553
    },
    {
      Country: "North America",
      date: new Date("2010-01-01"),
      Renewables_percentage: 8.308023
    },
    {
      Country: "North America",
      date: new Date("2011-01-01"),
      Renewables_percentage: 9.318538
    },
    {
      Country: "North America",
      date: new Date("2012-01-01"),
      Renewables_percentage: 9.387884
    },
    {
      Country: "North America",
      date: new Date("2013-01-01"),
      Renewables_percentage: 9.56682
    },
    {
      Country: "North America",
      date: new Date("2014-01-01"),
      Renewables_percentage: 9.663418
    },
    {
      Country: "North America",
      date: new Date("2015-01-01"),
      Renewables_percentage: 9.893767
    },
    {
      Country: "North America",
      date: new Date("2016-01-01"),
      Renewables_percentage: 10.619578
    },
    {
      Country: "North America",
      date: new Date("2017-01-01"),
      Renewables_percentage: 11.303117
    },
    {
      Country: "North America",
      date: new Date("2018-01-01"),
      Renewables_percentage: 11.098234
    },
    {
      Country: "North America",
      date: new Date("2019-01-01"),
      Renewables_percentage: 11.372348
    },
    {
      Country: "North America",
      date: new Date("2020-01-01"),
      Renewables_percentage: 12.788118
    },
    {
      Country: "North America",
      date: new Date("2021-01-01"),
      Renewables_percentage: 12.669527
    },
    {
      Country: "North America",
      date: new Date("2022-01-01"),
      Renewables_percentage: 13.275697
    },
    {
      Country: "Oceania",
      date: new Date("1965-01-01"),
      Renewables_percentage: 11.181268
    },
    {
      Country: "Oceania",
      date: new Date("1966-01-01"),
      Renewables_percentage: 10.888557
    },
    {
      Country: "Oceania",
      date: new Date("1967-01-01"),
      Renewables_percentage: 10.597106
    },
    {
      Country: "Oceania",
      date: new Date("1968-01-01"),
      Renewables_percentage: 10.568055
    },
    {
      Country: "Oceania",
      date: new Date("1969-01-01"),
      Renewables_percentage: 10.485675
    },
    {
      Country: "Oceania",
      date: new Date("1970-01-01"),
      Renewables_percentage: 10.650264
    },
    {
      Country: "Oceania",
      date: new Date("1971-01-01"),
      Renewables_percentage: 11.413852
    },
    {
      Country: "Oceania",
      date: new Date("1972-01-01"),
      Renewables_percentage: 11.253138
    },
    {
      Country: "Oceania",
      date: new Date("1973-01-01"),
      Renewables_percentage: 10.800493
    },
    {
      Country: "Oceania",
      date: new Date("1974-01-01"),
      Renewables_percentage: 11.169483
    },
    {
      Country: "Oceania",
      date: new Date("1975-01-01"),
      Renewables_percentage: 11.884283
    },
    {
      Country: "Oceania",
      date: new Date("1976-01-01"),
      Renewables_percentage: 10.830898
    },
    {
      Country: "Oceania",
      date: new Date("1977-01-01"),
      Renewables_percentage: 9.875237
    },
    {
      Country: "Oceania",
      date: new Date("1978-01-01"),
      Renewables_percentage: 10.272689
    },
    {
      Country: "Oceania",
      date: new Date("1979-01-01"),
      Renewables_percentage: 10.619365
    },
    {
      Country: "Oceania",
      date: new Date("1980-01-01"),
      Renewables_percentage: 10.64384
    },
    {
      Country: "Oceania",
      date: new Date("1981-01-01"),
      Renewables_percentage: 10.705261
    },
    {
      Country: "Oceania",
      date: new Date("1982-01-01"),
      Renewables_percentage: 10.048258
    },
    {
      Country: "Oceania",
      date: new Date("1983-01-01"),
      Renewables_percentage: 10.515341
    },
    {
      Country: "Oceania",
      date: new Date("1984-01-01"),
      Renewables_percentage: 10.621479
    },
    {
      Country: "Oceania",
      date: new Date("1985-01-01"),
      Renewables_percentage: 10.362133
    },
    {
      Country: "Oceania",
      date: new Date("1986-01-01"),
      Renewables_percentage: 10.78609
    },
    {
      Country: "Oceania",
      date: new Date("1987-01-01"),
      Renewables_percentage: 10.351655
    },
    {
      Country: "Oceania",
      date: new Date("1988-01-01"),
      Renewables_percentage: 10.434978
    },
    {
      Country: "Oceania",
      date: new Date("1989-01-01"),
      Renewables_percentage: 9.991632
    },
    {
      Country: "Oceania",
      date: new Date("1990-01-01"),
      Renewables_percentage: 10.110948
    },
    {
      Country: "Oceania",
      date: new Date("1991-01-01"),
      Renewables_percentage: 10.321624
    },
    {
      Country: "Oceania",
      date: new Date("1992-01-01"),
      Renewables_percentage: 9.790664
    },
    {
      Country: "Oceania",
      date: new Date("1993-01-01"),
      Renewables_percentage: 10.279025
    },
    {
      Country: "Oceania",
      date: new Date("1994-01-01"),
      Renewables_percentage: 10.321463
    },
    {
      Country: "Oceania",
      date: new Date("1995-01-01"),
      Renewables_percentage: 10.170567
    },
    {
      Country: "Oceania",
      date: new Date("1996-01-01"),
      Renewables_percentage: 9.703573
    },
    {
      Country: "Oceania",
      date: new Date("1997-01-01"),
      Renewables_percentage: 8.93261
    },
    {
      Country: "Oceania",
      date: new Date("1998-01-01"),
      Renewables_percentage: 9.214099
    },
    {
      Country: "Oceania",
      date: new Date("1999-01-01"),
      Renewables_percentage: 8.585494
    },
    {
      Country: "Oceania",
      date: new Date("2000-01-01"),
      Renewables_percentage: 8.834102
    },
    {
      Country: "Oceania",
      date: new Date("2001-01-01"),
      Renewables_percentage: 8.116986
    },
    {
      Country: "Oceania",
      date: new Date("2002-01-01"),
      Renewables_percentage: 8.589448
    },
    {
      Country: "Oceania",
      date: new Date("2003-01-01"),
      Renewables_percentage: 8.364993
    },
    {
      Country: "Oceania",
      date: new Date("2004-01-01"),
      Renewables_percentage: 8.900089
    },
    {
      Country: "Oceania",
      date: new Date("2005-01-01"),
      Renewables_percentage: 8.550485
    },
    {
      Country: "Oceania",
      date: new Date("2006-01-01"),
      Renewables_percentage: 8.380762
    },
    {
      Country: "Oceania",
      date: new Date("2007-01-01"),
      Renewables_percentage: 8.140984
    },
    {
      Country: "Oceania",
      date: new Date("2008-01-01"),
      Renewables_percentage: 7.705062
    },
    {
      Country: "Oceania",
      date: new Date("2009-01-01"),
      Renewables_percentage: 8.413878
    },
    {
      Country: "Oceania",
      date: new Date("2010-01-01"),
      Renewables_percentage: 8.962894
    },
    {
      Country: "Oceania",
      date: new Date("2011-01-01"),
      Renewables_percentage: 10.150567
    },
    {
      Country: "Oceania",
      date: new Date("2012-01-01"),
      Renewables_percentage: 9.88151
    },
    {
      Country: "Oceania",
      date: new Date("2013-01-01"),
      Renewables_percentage: 10.578319
    },
    {
      Country: "Oceania",
      date: new Date("2014-01-01"),
      Renewables_percentage: 10.325295
    },
    {
      Country: "Oceania",
      date: new Date("2015-01-01"),
      Renewables_percentage: 10.61672
    },
    {
      Country: "Oceania",
      date: new Date("2016-01-01"),
      Renewables_percentage: 11.538693
    },
    {
      Country: "Oceania",
      date: new Date("2017-01-01"),
      Renewables_percentage: 10.970324
    },
    {
      Country: "Oceania",
      date: new Date("2018-01-01"),
      Renewables_percentage: 12.3616
    },
    {
      Country: "Oceania",
      date: new Date("2019-01-01"),
      Renewables_percentage: 12.659211
    },
    {
      Country: "Oceania",
      date: new Date("2020-01-01"),
      Renewables_percentage: 14.531903
    },
    {
      Country: "Oceania",
      date: new Date("2021-01-01"),
      Renewables_percentage: 16.40427
    },
    {
      Country: "Oceania",
      date: new Date("2022-01-01"),
      Renewables_percentage: 17.97489
    },
    {
      Country: "South America",
      date: new Date("1965-01-01"),
      Renewables_percentage: 10.950933
    },
    {
      Country: "South America",
      date: new Date("1966-01-01"),
      Renewables_percentage: 11.442433
    },
    {
      Country: "South America",
      date: new Date("1967-01-01"),
      Renewables_percentage: 11.675976
    },
    {
      Country: "South America",
      date: new Date("1968-01-01"),
      Renewables_percentage: 11.614463
    },
    {
      Country: "South America",
      date: new Date("1969-01-01"),
      Renewables_percentage: 11.965155
    },
    {
      Country: "South America",
      date: new Date("1970-01-01"),
      Renewables_percentage: 13.72703
    },
    {
      Country: "South America",
      date: new Date("1971-01-01"),
      Renewables_percentage: 14.363029
    },
    {
      Country: "South America",
      date: new Date("1972-01-01"),
      Renewables_percentage: 15.235446
    },
    {
      Country: "South America",
      date: new Date("1973-01-01"),
      Renewables_percentage: 15.541877
    },
    {
      Country: "South America",
      date: new Date("1974-01-01"),
      Renewables_percentage: 16.565619
    },
    {
      Country: "South America",
      date: new Date("1975-01-01"),
      Renewables_percentage: 17.728695
    },
    {
      Country: "South America",
      date: new Date("1976-01-01"),
      Renewables_percentage: 18.377558
    },
    {
      Country: "South America",
      date: new Date("1977-01-01"),
      Renewables_percentage: 19.364433
    },
    {
      Country: "South America",
      date: new Date("1978-01-01"),
      Renewables_percentage: 20.289463
    },
    {
      Country: "South America",
      date: new Date("1979-01-01"),
      Renewables_percentage: 21.441954
    },
    {
      Country: "South America",
      date: new Date("1980-01-01"),
      Renewables_percentage: 23.149178
    },
    {
      Country: "South America",
      date: new Date("1981-01-01"),
      Renewables_percentage: 23.407757
    },
    {
      Country: "South America",
      date: new Date("1982-01-01"),
      Renewables_percentage: 24.896477
    },
    {
      Country: "South America",
      date: new Date("1983-01-01"),
      Renewables_percentage: 26.908762
    },
    {
      Country: "South America",
      date: new Date("1984-01-01"),
      Renewables_percentage: 28.6515
    },
    {
      Country: "South America",
      date: new Date("1985-01-01"),
      Renewables_percentage: 29.91094
    },
    {
      Country: "South America",
      date: new Date("1986-01-01"),
      Renewables_percentage: 30.554363
    },
    {
      Country: "South America",
      date: new Date("1987-01-01"),
      Renewables_percentage: 31.298008
    },
    {
      Country: "South America",
      date: new Date("1988-01-01"),
      Renewables_percentage: 31.46088
    },
    {
      Country: "South America",
      date: new Date("1989-01-01"),
      Renewables_percentage: 31.789536
    },
    {
      Country: "South America",
      date: new Date("1990-01-01"),
      Renewables_percentage: 32.580894
    },
    {
      Country: "South America",
      date: new Date("1991-01-01"),
      Renewables_percentage: 33.69266
    },
    {
      Country: "South America",
      date: new Date("1992-01-01"),
      Renewables_percentage: 33.139412
    },
    {
      Country: "South America",
      date: new Date("1993-01-01"),
      Renewables_percentage: 34.231358
    },
    {
      Country: "South America",
      date: new Date("1994-01-01"),
      Renewables_percentage: 34.24824
    },
    {
      Country: "South America",
      date: new Date("1995-01-01"),
      Renewables_percentage: 33.78421
    },
    {
      Country: "South America",
      date: new Date("1996-01-01"),
      Renewables_percentage: 33.660652
    },
    {
      Country: "South America",
      date: new Date("1997-01-01"),
      Renewables_percentage: 33.587643
    },
    {
      Country: "South America",
      date: new Date("1998-01-01"),
      Renewables_percentage: 33.09469
    },
    {
      Country: "South America",
      date: new Date("1999-01-01"),
      Renewables_percentage: 32.987396
    },
    {
      Country: "South America",
      date: new Date("2000-01-01"),
      Renewables_percentage: 33.56873
    },
    {
      Country: "South America",
      date: new Date("2001-01-01"),
      Renewables_percentage: 31.83885
    },
    {
      Country: "South America",
      date: new Date("2002-01-01"),
      Renewables_percentage: 32.96632
    },
    {
      Country: "South America",
      date: new Date("2003-01-01"),
      Renewables_percentage: 33.772526
    },
    {
      Country: "South America",
      date: new Date("2004-01-01"),
      Renewables_percentage: 32.975006
    },
    {
      Country: "South America",
      date: new Date("2005-01-01"),
      Renewables_percentage: 33.71303
    },
    {
      Country: "South America",
      date: new Date("2006-01-01"),
      Renewables_percentage: 33.54026
    },
    {
      Country: "South America",
      date: new Date("2007-01-01"),
      Renewables_percentage: 33.400215
    },
    {
      Country: "South America",
      date: new Date("2008-01-01"),
      Renewables_percentage: 33.33666
    },
    {
      Country: "South America",
      date: new Date("2009-01-01"),
      Renewables_percentage: 34.767105
    },
    {
      Country: "South America",
      date: new Date("2010-01-01"),
      Renewables_percentage: 33.55369
    },
    {
      Country: "South America",
      date: new Date("2011-01-01"),
      Renewables_percentage: 33.815956
    },
    {
      Country: "South America",
      date: new Date("2012-01-01"),
      Renewables_percentage: 32.19014
    },
    {
      Country: "South America",
      date: new Date("2013-01-01"),
      Renewables_percentage: 31.145796
    },
    {
      Country: "South America",
      date: new Date("2014-01-01"),
      Renewables_percentage: 30.74058
    },
    {
      Country: "South America",
      date: new Date("2015-01-01"),
      Renewables_percentage: 31.29707
    },
    {
      Country: "South America",
      date: new Date("2016-01-01"),
      Renewables_percentage: 32.497185
    },
    {
      Country: "South America",
      date: new Date("2017-01-01"),
      Renewables_percentage: 33.365993
    },
    {
      Country: "South America",
      date: new Date("2018-01-01"),
      Renewables_percentage: 35.24583
    },
    {
      Country: "South America",
      date: new Date("2019-01-01"),
      Renewables_percentage: 36.469112
    },
    {
      Country: "South America",
      date: new Date("2020-01-01"),
      Renewables_percentage: 38.96967
    },
    {
      Country: "South America",
      date: new Date("2021-01-01"),
      Renewables_percentage: 35.738064
    },
    {
      Country: "South America",
      date: new Date("2022-01-01"),
      Renewables_percentage: 37.78336
    }
  ]

export default  energy_total_continents;
