import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AreaChart = (props) => {
  const ref = useRef();
  const data = props.data;

  // Declare the chart dimensions and margins.
  const width = 840;
  const height = 300;
  const marginTop = 40;
  const marginRight = 20;
  const marginBottom = 30;
  const marginLeft = 40;
  const areaColors = [
    "#838996",
    "#123456",
    "#789abc",
    "#def012",
    "#345678",
    "#2E8B57",
  ];

  function createArea() {
    const series = d3
      .stack()
      .keys(d3.union(data.map((d) => d.Country))) // apples, bananas, cherries, …
      .value(([, group], key) => group.get(key).Renewables_percentage)(
      d3.index(
        data,
        (d) => d.date,
        (d) => d.Country
      )
    );

    // 1.1. Declare the x (horizontal position) scale.
    // Prepare the scales for positional and color encodings.
    const xScale = d3
      .scaleUtc()
      .domain(d3.extent(data, (d) => d.date))
      .range([marginLeft, width - marginRight]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
      .rangeRound([height - marginBottom, marginTop]);

    // console.log(series)

    const color = d3
      .scaleOrdinal()
      .domain(series.map((d) => d.key))
      .range(areaColors);

    // 2.1. Declare the area generator.
    // Construct an area shape.
    const area = d3
      .area()
      .x((d) => xScale(d.data[0]))
      .y0((d) => yScale(d[0]))
      .y1((d) => yScale(d[1]));

    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("style", `max-width: 100%; height: auto;`);

    //clean the chart before painting
    svg.selectAll("*").remove();

    // 3.1. Append a path for the area (under the axes).
    // Add the y-axis, remove the domain line, add grid lines and a label.
    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(yScale).ticks(height / 80))
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .clone()
          .attr("x2", width - marginLeft - marginRight)
          .attr("stroke-opacity", 0.1)
      )
      .call((g) =>
        g
          .append("text")
          .attr("x", -marginLeft)
          .attr("y", 27)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .style("font-weight", "bold")
          .style("font-size", "12px")
          .text("Renewables Energy per Continent (%)")
      );

    // Define a clipPath
    svg
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", 0)
      .attr("height", height);

    // Append a path for each series.
    svg
      .append("g")
      .selectAll("path")
      .data(series)
      .join("path")
      .attr("fill", (d) => color(d.key))
      .attr("d", area)
      .attr("clip-path", "url(#clip)"); // Use the clipPath

    // Animate the clipPath
    svg
      .select("#clip rect")
      .transition()
      .duration(5000) // Make it last 2 seconds
      .attr("width", width);

    // Add the legend
    const legend = svg
      .selectAll(".legend")
      .data(series)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${i * 73},0)`); // Move the legend to the right

    // position of the text element of the legend
    const legendText = legend
      .append("text")
      .attr("x", 270)
      .attr("y", 27)
      .style("text-anchor", "start")
      .style("font-size", "8px")
      .text((d) => d.key);

    // position of the rect element of the legend
    legend
      .append("rect")
      .attr("x", (d, i) => 270 + legendText.nodes()[i].getBBox().width + 3) // Add 10 for some padding
      .attr("y", 20)
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", (d) => color(d.key));

    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(xScale).tickSizeOuter(0));
  }

  useEffect(() => {
    createArea();

    const intervalId = setInterval(() => {
      createArea();
    }, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [data]);

  return <svg ref={ref}></svg>;
};

export default AreaChart;
