import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import AppRouter from "./components/AppRouter";


function App() {
  return (
    <React.Fragment>
      <AppRouter />
      <footer className="py-2 text-center bg-body-tertiary bg-primary">
        <h6>Copyright © 2023 Barbones. All rights reserved.</h6>
      </footer>
    </React.Fragment>
  );
}

export default App;
