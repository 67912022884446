const coursesdata = [
    {
        id: 1,
        name: "sparksql",
        title: "Spark SQL",
        programming: [{language: "Scala"}, {language: "Python"}],
        summary_content: "You can create Spark SQL structures to develop and improve the engine en your profesional path.",
        content: "This tutorial will guide you across Apache Spark joining to a variate tools such Pydantic, Apache Nifi, Sql Models, etc. I will share practical examples where you will be able to create functional definitions to analyse data with Apache Spark.",
        image: require("../../images/portfolio/spark.jpg"),
        image_logo: require("../../images/portfolio/apache_spark_logo.png"),
        image_title_prompt: require("../../images/portfolio/spark_sql_prompt.png"),
        button_portfolio_description: "Read More",
        button_portfolio_status: 1,
        customer_target: "To take this course you have to feel confidence with SQL sentences. Also, have experience with object oriented programing and functional programing to have a better approach to the real environment",
        course_requirements: {
            knowledge: "Basic SQL knowledge and language programing",
            profiles: "Preferent profiles Tech & data analytics",
            accounts: "(Optional) Digital accounts for Github",
            localhost: "(Optional) IDE to work with or Text Editor",
            tools: "Tools installed: Python, Apache Spark"
        },
        course_goals: {
            1: "Create PySpark Dataframe using Pydantic Models",
            2: "Working with external sources",
            3: "Ability to use Spark SQL to solve the problems using API PySpark style syntax.",
            4: "Schema Evolution, Customizations and Enforcement",
            5: "Advanced techniques with PySpark"
        },
        course_detail: {
            duration: "1 hour of examples",
            resources: "Github repository with examples",
            access: "Full lifetime access to repository",
            total_time: "Practical Examples",
        },
        syllabus: [
            {
                title: "Spark Fundamentals & Setting Up",
                module: "Module 1",
                classes: "2 Topics",
                content: [
                    {
                        code: "m1c1",
                        topic:
                            "Setting up a work environment",
                    },
                    {
                        code: "m1c2",
                        topic:
                            "DataFrame API vs. Spark SQL",
                    },
                ],
            },
            {
                title: "Data Ingestion & Schema Evolution",
                module: "Module 2",
                classes: "4 Topics",
                content: [
                    {
                        code: "m2c1",
                        topic:
                            "Loading data from various sources",
                    },
                    {
                        code: "m2c2",
                        topic:
                            "Handing schema evolution and data type inference",
                    },
                    {
                        code: "m2c3",
                        topic:
                            "Schema customization and enforcement",
                    },
                    {
                        code: "m2c4",
                        topic:
                            "Working with Pydantic Models and DataClass",
                    },
                ],
            },
            {
                title: "Data Exploration & Manipulation",
                module: "Module 3",
                classes: "3 Topics",
                content: [
                    {
                        code: "m3c1",
                        topic:
                            "Filtering, selecting, and aggregation data",
                    },
                    {
                        code: "m3c2",
                        topic:
                            "Join operations",
                    },
                    {
                        code: "m3c3",
                        topic:
                            "Windows functions and advanced aggregations",
                    },
                ],
            },
            {
                title: "Advance Query Optimization",
                module: "Module 4",
                classes: "4 Topics",
                content: [
                    {
                        code: "m4c1",
                        topic:
                            "Query optimization techniques",
                    },
                    {
                        code: "m4c2",
                        topic:
                            "Catalyst optimizer and tungsten execution engine",
                    },
                    {
                        code: "m4c3",
                        topic:
                            "Caching and data persistence strategies",
                    },
                    {
                        code: "m4c4",
                        topic:
                            "Performance tunning best practices",
                    },
                ],
            },
            {
                title: "Working With External Data Sources",
                module: "Module 5",
                classes: "2 hours",
                content: [
                    {
                        code: "m5c1",
                        topic:
                            "Integrating with external data stores (Hive, HBase, Cassandra, etc.)",
                    },
                    {
                        code: "m5c2",
                        topic:
                            "Reading and writing data from and to external systems",
                    },
                    {
                        code: "m5c3",
                        topic:
                            "Schema management and synchronization",
                    },
                ],
            },
            {
                title: "Real-world Applications & Best Practices",
                module: "Module 6",
                classes: "4 hours",
                content: [
                    {
                        code: "m6c1",
                        topic:
                            "Building end-to-end data pipelines",
                    },
                    {
                        code: "m6c2",
                        topic:
                            "Handling complex data transformations and ETL processes",
                    },
                    {
                        code: "m6c3",
                        topic:
                            "Security and authentication",
                    },
                ],
            },
        ],
        questions: [
            {
                title: "¿Cuándo empieza el curso?",
                answer: "Los cursos son 100% online On-demand lo que significa que están grabados y los puedes empezar cuando quieras y a tu propio ritmo."
            },
            {
                title: "¿Cómo funciona la membresía premium?",
                answer: "Al suscribirte tendrás acceso ilimitado a todos los cursos. En tu cuenta podrás agregar cursos, ver cualquier video, tener seguimiento de tus cursos y en los cursos que lo indican: presentar un examen al final y obtener un certificado digital. Además tendrás acceso exclusivo a clases premium semanales que son en vivo donde puedes hacer preguntas al instructor. Cada mes agregamos nuevos contenidos, actualizando cursos existentes y cursos completamente nuevos. La membresía se actualiza de manera recurrente y puedes cancelar en cualquier momento desde tu cuenta en un par de clics."
            },
            {
                title: "¿Qué puedo aprender?",
                answer: "Nos especializamos en programación: desde lo básico hasta lo pro. Frontend, HTML5, CSS, JavaScript, backend con Python, Rails y mucho más. Puedes explorar nuestro catálogo completo aquí."
            },
            {title: "Tengo otra pregunta, ¿dónde los contacto?", answer: "Te atendemos con gusto en ayuda@xxxxxx.com"},
        ],
    },
    {
        id: 2,
        name: "d3.js",
        title: "D3.js Charting",
        programming: [{language: "Javascript"}],
        summary_content: "Dashboard based on d3.js where you can learn how to build it",
        content: "This dashboard example has been created to show you how to build a dashboard with d3.js. Also, it is using plastic data or unreal data. You can use this example to create your own dashboard with real data.",
        image: require("../../images/portfolio/d3.png"),
        image_title_prompt: require("../../images/analytics/d3.webp"),
        button_portfolio_description: "View Details",
        button_portfolio_status: 1
        ,
        customer_target: "To take ths course you need to have experience with SQL ansi and understand the dataset procesing. Essentially, have experience with object oriente programing and functional programing",
        course_detail: {
            duration: "8.5 hours on demand-video",
            resources: "10 articules",
            access: "Full lifetime access",
            total_time: "Certificate of completion",
        },
        syllabus: [
            {
                title: "Spark fundamentals",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Working with RDD",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Spark Scala SQL functions",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Scala for comprehension",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Spark join examples",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
        ],
        questions: [
            {title: "", answer: ""},
            {title: "", answer: ""},
            {title: "", answer: ""},
            {title: "", answer: ""},
        ],
    },
    {
        id: 3,
        name: "kafka",
        title: "Apache Kafka",
        programming: [{language: "Python"}],
        summary_content: "Streaming processing based on kafka, exposed with videos and tutorials",
        content: "You can create Spark SQL structures to develop and improve the engine en your profesional path. ",
        image: require("../../images/portfolio/kafka.png"),
        button_portfolio_description: "Examples",
        button_portfolio_status: "",
        customer_target: "To take ths course you need to have experience with SQL ansi and understand the dataset procesing. Essentially, have experience with object oriente programing and functional programing",
        course_detail: {
            duration: "8.5 hours on demand-video",
            resources: "10 articules",
            access: "Full lifetime access",
            total_time: "Certificate of completion",
        },
        syllabus: [
            {
                title: "Spark fundamentals",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Working with RDD",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Spark Scala SQL functions",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Scala for comprehension",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
            {
                title: "Spark join examples",
                content: [
                    {
                        topic:
                            "Lorem1 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem2 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                    {
                        topic:
                            "Lorem3 ipsum dolor sit amet, consectetur adipiscing elit, sed ",
                    },
                ],
            },
        ],
        questions: [
            {title: "", answer: ""},
            {title: "", answer: ""},
            {title: "", answer: ""},
            {title: "", answer: ""},
        ],
    },
];

export default coursesdata;
