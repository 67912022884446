import React from "react";
import "./stylesheet.css";
import './Landing.css';
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Testimonial from "./Testimonial";
import Home from "./Home";
import WorkingMe from "./WorkingMe";


const MainLanding = () => {
  return (
    <div id="content" role="main">
      <section>
        <Home />
      </section>
      <section id="about" className="section">
        <About />
      </section>
      <section id="portfolio" className="section bg-light">
        <Portfolio />
      </section>
      <section>
        <WorkingMe />
      </section>
      <section id="testimonial" className="section bg-secondary">
        <Testimonial />
      </section>
      <section id="contact" className="section bg-primary">
        <Contact />
      </section>
    </div>
  );
};

export default MainLanding;
